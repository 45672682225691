import { useContextSelector } from 'use-context-selector';

import { cn } from '@/core/ui/utils';
import { selectedL0CategorySelector } from '@/store/selectors/categoriesSelectors';
import { useAppSelector } from '@/store/storeHooks';
import { useWindowScrollDirection } from '@lib/theme/hooks/useWindowScrollDirection';

import { NavBarBoxContext } from '../contexts/NavBarBoxContext';

import { NavCategoriesList } from './NavCategoriesList';

export const SecondaryHorizontalNavInner = () => {
  const selectedL0CategoryKey = useAppSelector(selectedL0CategorySelector);
  const { navBarBottomPosition, secondaryNavBarRef } = useContextSelector(
    NavBarBoxContext,
    ({ navBarBottomPosition, secondaryNavBarRef }) => ({
      navBarBottomPosition,
      secondaryNavBarRef,
    })
  );

  const scrollDirection = useWindowScrollDirection({
    onAfterScroll: ({ prevWindowScrollY, setDirection }) => {
      if (prevWindowScrollY >= SCROLL_THRESHOLD) return;
      setDirection(null);
    },
  });

  const getTopPosition = (navBarBottomPosition: number) => {
    if (scrollDirection === 'up' || selectedL0CategoryKey) {
      return navBarBottomPosition;
    }

    return -navBarBottomPosition;
  };

  return (
    <div
      ref={secondaryNavBarRef}
      className={cn('bg-white', 'transition-all', 'px-4', 'xl:px-0')}
      style={
        typeof navBarBottomPosition !== 'undefined'
          ? {
              top: getTopPosition(navBarBottomPosition),
            }
          : undefined
      }
    >
      <NavCategoriesList />
    </div>
  );
};
const SCROLL_THRESHOLD = 5;
