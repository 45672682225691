import Image from 'next/image';
import Link from 'next/link';
import { useTranslation } from 'next-i18next';

import mobile from '@/core/images/mobile.png';

export const AccountMenuAppBanner = () => {
  const { t } = useTranslation('layout');

  return (
    <Link
      href="/zabka-jush"
      className="mt-4 flex items-center justify-between rounded-xl bg-decorative-surface-two px-3 text-body-md font-medium"
    >
      {t('Check out the new app!')}
      <Image
        src={mobile.src}
        width={77}
        height={64}
        quality={100}
        alt=""
        className="h-16"
      />
    </Link>
  );
};
