import { useShippingAddress } from '@/core/hooks/useShippingAddress';
import { selectCoordinatesFromAddress } from '@/modules/checkout/selectors/selectCoordinatesFromAddress';
import { useStore } from '@/modules/store/hooks/useStore';

import { Address } from '../types';

type Status = 'loading' | 'resolved' | 'rejected';

export const useAddressData = () => {
  const { store, loading: storeLoading, error } = useStore();
  const shippingAddress = useShippingAddress();
  const coordinates = selectCoordinatesFromAddress(shippingAddress.data);

  const address: Address | null = shippingAddress.data
    ? {
        streetName: shippingAddress.data.streetName ?? '',
        streetNumber: shippingAddress.data.streetNumber ?? '',
        apartment: shippingAddress.data.apartment ?? null,
        postalCode: shippingAddress.data.postalCode ?? '',
        city: shippingAddress.data.city ?? '',
        floor: shippingAddress.data.floor ?? null,
      }
    : null;

  const data =
    address && coordinates && store
      ? {
          coordinates,
          address,
          darkstore: store,
        }
      : null;

  const status: Status = getStatus(!!error, storeLoading);

  return {
    data,
    status,
  };
};

const getStatus = (isError: boolean, storeLoading: boolean): Status => {
  if (isError) {
    return 'rejected';
  }
  if (storeLoading) {
    return 'loading';
  }
  return 'resolved';
};
