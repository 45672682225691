import { ArrowRight } from '@phosphor-icons/react';
import Link from 'next/link';
import { ReactNode, useMemo } from 'react';

import { DEFAULT_ICON_SIZES } from '@/core/constants/iconSizes';
import { cn } from '@/core/ui/utils';

type BaseProps = {
  title: string;
  icon: ReactNode;
};

type LinkProps = BaseProps & {
  href: string;
};

type ButtonProps = BaseProps & {
  onClick: () => void;
  disabled?: boolean;
};

type AccountMenuItemProps = LinkProps | ButtonProps;

const classes = [
  'py-3',
  'px-2.5',
  'text-text-default',
  'flex',
  'items-center',
  'rounded-xl',
  'transition-colors',
  'group',
  'w-full',
  'hover:bg-surface-subtle',
  'hover:text-action-primary-default',
];

export const AccountMenuItem = ({
  title,
  icon,
  ...props
}: AccountMenuItemProps) => {
  const Content = useMemo(
    () => (
      <>
        {icon}
        <span className="ml-2.5 text-body-md font-medium">{title}</span>

        <ArrowRight
          size={DEFAULT_ICON_SIZES.md}
          className="ml-auto text-action-primary-default opacity-0 group-hover:opacity-100"
        />
      </>
    ),
    [icon, title]
  );

  if ('href' in props) {
    return (
      <Link href={props.href} className={cn(classes)}>
        {Content}
      </Link>
    );
  }
  return (
    <button
      onClick={props.onClick}
      type="button"
      className={cn(classes)}
      disabled={props?.disabled}
    >
      {Content}
    </button>
  );
};
