import { cn } from '@/core/ui/utils';

import { useAddressData } from '../../../location/hooks/useAddressData';
import { useLocationModal } from '../../../location/hooks/useLocationModal';

import { LocationButton } from './components/LocationButton';

export const LocationPill = () => {
  const { openLocationModal } = useLocationModal();
  const { status } = useAddressData();

  if (status === 'loading') {
    return (
      <div
        className={cn(
          'bg-gray-200',
          'animate-pulse',
          'rounded-xl',
          'h-full',
          'w-full'
        )}
      />
    );
  }

  return <LocationButton onClick={openLocationModal} />;
};
