import { useMemo } from 'react';

import { useUserAddressCoordinates } from '@/core/hooks/useUserAddressCoordinates';

import { useAnnouncementsQuery } from '../../../core/graphql/queries/Announcements/Announcements.delio.generated';

export const useAnnouncements = () => {
  const { coordinates, loading } = useUserAddressCoordinates();

  const announcementsResult = useAnnouncementsQuery({
    variables: {
      coordinates,
    },
    skip: loading,
  });

  const data = announcementsResult.data ?? announcementsResult.previousData;
  const { loading: isLoading, error } = announcementsResult;

  return useMemo(
    () => ({
      data,
      isLoading: isLoading || loading,
      error: data ? undefined : error,
    }),
    [data, error, isLoading, loading]
  );
};
