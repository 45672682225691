import { forwardRef, HTMLAttributes } from 'react';

import { cn } from '@/core/ui/utils';

const skeletonClasses = cn('animate-pulse', 'bg-black', 'opacity-[0.08]');
const contentClasses = cn(skeletonClasses, 'rounded-md', 'h-5');
const MAX_SKELETONS = 4;

export const ProductSearchMenuSkeleton = forwardRef<
  HTMLUListElement,
  HTMLAttributes<HTMLUListElement>
>((props, ref) => (
  <div>
    <div
      className={cn(
        skeletonClasses,
        'rounded-md',
        'h-5',
        'w-full',
        'max-w-[174px]'
      )}
    />
    <ul
      className={cn('flex', 'flex-col', 'gap-3', 'mt-5', 'mb-6')}
      {...props}
      ref={ref}
    >
      {[...Array(MAX_SKELETONS).keys()].map((_, index) => (
        <ProductSkeleton key={index} />
      ))}
    </ul>
    <div
      className={cn(
        skeletonClasses,
        'rounded-3xl',
        'h-5',
        'w-full',
        'max-w-[182px]',
        'mx-auto',
        'mb-2'
      )}
    />
  </div>
));

const ProductSkeleton = () => (
  <li
    className={cn(
      'flex',
      'items-center',
      'border',
      'border-border-subtle',
      'rounded-lg',
      'gap-x-3',
      'h-[88px]',
      'px-4'
    )}
  >
    <div className={cn('w-12', 'h-12', 'rounded-sm', skeletonClasses)} />
    <div className={cn('flex', 'flex-col', 'gap-2', 'max-w-[231px]', 'w-full')}>
      <div className={contentClasses} />
      <div className={contentClasses} />
    </div>
    <div
      className={cn('ml-auto', 'h-5', 'rounded-3xl', 'w-14', skeletonClasses)}
    />
  </li>
);
