import { analytics } from '@/config/analytics';

export const sendSearchQuerySetEvent = ({
  query,
  sku,
  attributionToken,
}: SearchQuerySetEventArgs) => {
  const payload: SearchQuerySetPayload = {
    query,
    sku,
    attribution_token: attributionToken,
    version: '0.2',
  };

  return analytics.track('Search Query Set', payload);
};

export type SearchQuerySetEventArgs = {
  sku: string[];
  query: string;
  attributionToken?: string;
};

type SearchQuerySetPayload = {
  sku: string[];
  query: string;
  attribution_token?: string;
  version: string;
};
