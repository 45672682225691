import { forwardRef, InputHTMLAttributes } from 'react';

import { cn } from '@/core/ui/utils';

type ProductSearchFieldInputProps = InputHTMLAttributes<HTMLInputElement>;

export const ProductSearchFieldInput = forwardRef<
  HTMLInputElement,
  ProductSearchFieldInputProps
>(({ placeholder, className, maxLength, ...rest }, ref) => (
  <input
    ref={ref}
    maxLength={maxLength ?? DEFAULT_MAX_LENGTH}
    placeholder={placeholder}
    className={cn(
      'outline-none',
      'pl-4',
      'rounded-xl',
      'w-full',
      'block',
      'bg-surface-subtle',
      'h-12',
      'border',
      'border-border-subtle',
      'text-body-md',
      'text-text-default',
      'truncate',
      'placeholder:text-body-md',
      'placeholder-text-subtle',
      'hover:border-border-hovered',
      'focus:border-border-focused',
      'focus:border-action-primary-focused',
      'focus:bg-transparent',
      'focus:shadow-borderFocusedSm',
      className
    )}
    {...rest}
  />
));

const DEFAULT_MAX_LENGTH = 100;
