import {
  Popover,
  PopoverButton,
  PopoverPanel,
  Transition,
} from '@headlessui/react';
import { useTranslation } from 'next-i18next';
import { ReactNode } from 'react';

import { useSession } from '@/modules/auth/hooks/useSession';
import { IconButton } from '@/modules/layout/components/IconButton/IconButton';
import UserIcon from '@/modules/layout/images/user-icon.svg';

type AccountMenuDesktopPopoverProps = {
  children: ReactNode;
};

export const AccountMenuDesktopPopover = ({
  children,
}: AccountMenuDesktopPopoverProps) => {
  const { t } = useTranslation('layout');
  const authRole = useSession('authRole');
  const isAuthenticated = authRole === 'AUTHENTICATED';

  return (
    <Popover>
      <>
        <PopoverButton
          className="focus-visible:outline-none xl:w-auto"
          as={IconButton}
        >
          <UserIcon className="size-6" />
          <p className="hidden whitespace-nowrap text-sm font-medium xl:block">
            {isAuthenticated ? t('Your account') : t('Sign in', { ns: 'auth' })}
          </p>
        </PopoverButton>
        <Transition
          enter="transition ease-out duration-200"
          enterFrom="opacity-0 translate-y-1"
          enterTo="opacity-100 translate-y-0"
          leave="transition ease-in duration-150"
          leaveFrom="opacity-100 translate-y-0"
          leaveTo="opacity-0 translate-y-1"
        >
          <PopoverPanel
            anchor={{ to: 'bottom', gap: 6 }}
            className="z-50 w-[284px] rounded-2xl border border-border-subtle bg-white shadow-md"
          >
            <div className="overflow-hidden p-2.5">{children}</div>
          </PopoverPanel>
        </Transition>
      </>
    </Popover>
  );
};
