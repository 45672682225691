import { cn } from '@/core/ui/utils';
import { FCC } from '@/types/common';
import { Dialog as BaseDialog } from '@headlessui/react';

export const Dialog: FCC<DialogProps> = ({ children, className, ...props }) => (
  <BaseDialog
    {...props}
    className={cn('fixed', 'inset-0', 'z-30', 'sm:z-30', 'flex', 'sm:p-4')}
  >
    <div className={cn('fixed', 'inset-0', 'bg-gray-800', 'opacity-90')} />
    <div
      className={cn(
        'relative',
        'bg-white',
        'z-30',
        'flex',
        'flex-col',
        'w-full',
        'h-full',
        'max-h-full',
        'sm:rounded-xl',
        'sm:h-auto',
        'sm:w-auto',
        'm-auto',
        'overflow-auto',
        className
      )}
    >
      {children}
    </div>
  </BaseDialog>
);

type DialogProps = {
  static?: boolean;
  onClose: () => void;
  open: boolean;
  className?: string;
};
