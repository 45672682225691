import { cn } from '@/core/ui/utils';
import { CategoryLink } from '@/modules/layout/components/CategoryLink';
import { selectL0CategoryKey } from '@/store/slices/categoriesSlice';
import { useAppDispatch } from '@/store/storeHooks';

export const NavL2Categories = ({
  categories,
  parentCategoryKey,
  cachedCategoryKey,
}: {
  categories: readonly {
    readonly __typename?: 'Category';
    readonly id: string;
    readonly key: string;
    readonly name: string;
    readonly orderHint: string;
    readonly childCount: number;
    readonly productCount: number;
  }[];
  parentCategoryKey: string;
  cachedCategoryKey?: string;
}) => {
  const dispatch = useAppDispatch();
  if (!cachedCategoryKey) return null;

  return (
    <ul>
      {categories
        .filter((el) => el.productCount > 0)
        .map((child) => (
          <li
            key={child.id}
            className={cn(
              'w-[203px]',
              'text-sm',
              'h-6',
              'flex',
              'items-center',
              'text-gray-900',
              'mb-1',
              'last:mb-0'
            )}
          >
            <CategoryLink
              l0CategoryKey={cachedCategoryKey}
              l1CategoryKey={parentCategoryKey}
              l2CategoryKey={child.key}
              onClick={() => dispatch(selectL0CategoryKey(null))}
            >
              <span className="hover:text-primary-450">{child.name}</span>
            </CategoryLink>
          </li>
        ))}
    </ul>
  );
};
