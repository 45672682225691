import { useCallback } from 'react';

import { useCurrentCart } from '@/core/hooks/useCurrentCart';
import { sendCartViewedEvent } from '@/modules/analytics/eventHandlers/sendCartViewedEvent';

export const useCartViewedEventHandler = () => {
  const cartResult = useCurrentCart();
  const currentCart = cartResult.data?.currentCart;

  return useCallback(() => {
    if (!currentCart) return;
    sendCartViewedEvent(currentCart);
  }, [currentCart]);
};
