import { ReactNode } from 'react';
import { useContextSelector } from 'use-context-selector';

import { cn } from '@/core/ui/utils';
import { ProductSearchContext } from '@/modules/product/context/ProductSearchContext';
import { Media } from '@lib/theme/components/Media';

type ProductSearchMobileContainerProps = {
  children?: ReactNode;
};

export const ProductSearchMobileContainer = ({
  children,
}: ProductSearchMobileContainerProps) => {
  const isOpen = useContextSelector(
    ProductSearchContext,
    (context) => context.combobox.isOpen
  );

  return (
    <>
      <div
        className={cn(
          'flex',
          'items-center',
          isOpen && [
            'pr-4',
            'py-3',
            'md:pt-4',
            'md:pr-5',
            'md:pb-2',
            'md:pl-1.5',
            'md:gap-x-1',
          ]
        )}
      >
        {children}
      </div>
      <Media lessThan="md">
        {isOpen && <div className="w-full border-t border-border-subtle" />}
      </Media>
    </>
  );
};
