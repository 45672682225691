import { forwardRef, HTMLAttributes } from 'react';

import { cn } from '@/core/ui/utils';

const skeletonClasses = cn('animate-pulse', 'bg-black', 'opacity-[0.08]');

export const ProductSearchMobileMenuSkeleton = forwardRef<
  HTMLUListElement,
  HTMLAttributes<HTMLUListElement>
>((props, ref) => (
  <div>
    <div
      className={cn(
        skeletonClasses,
        'rounded-3xl',
        'h-5',
        'w-full',
        'max-w-[199px]',
        'mt-6',
        'mx-4'
      )}
    />
    <ul ref={ref} {...props}>
      <li>
        <Item />
      </li>
    </ul>
  </div>
));

const Item = () => (
  <div className={cn('flex', 'h-[122px]', 'relative', 'w-full', 'p-4', 'pl-3')}>
    <div className={cn(skeletonClasses, 'min-w-[80px]', 'h-20', 'mr-3')} />
    <div className={cn('flex', 'flex-col', 'gap-1', 'w-full', 'mr-5')}>
      <div className={cn(skeletonClasses, 'h-5', 'w-full', 'rounded-3xl')} />
      <div
        className={cn(
          skeletonClasses,
          'h-4',
          'w-full',
          'max-w-[114px]',
          'rounded-3xl'
        )}
      />
      <div
        className={cn(
          skeletonClasses,
          'h-4',
          'w-full',
          'max-w-[34px]',
          'rounded-3xl'
        )}
      />
    </div>
    <div
      className={cn(skeletonClasses, 'min-w-[36px]', 'h-9', 'rounded-full')}
    />
    <div
      className={cn(
        skeletonClasses,
        'absolute',
        'right-4',
        'bottom-5',
        'w-full',
        'h-4',
        'max-w-[86px]',
        'rounded-3xl'
      )}
    />
  </div>
);
