import { useDebounce } from 'use-debounce';

import { MAX_SEARCH_PRODUCTS } from '@/core/constants/products';
import { useUserAddressCoordinates } from '@/core/hooks/useUserAddressCoordinates';
import { sendSearchQuerySetEvent } from '@/modules/analytics/eventHandlers/sendSearchQuerySetEvent';
import { sendSearchResultsEvent } from '@/modules/analytics/eventHandlers/sendSearchResultsEvent';

import {
  ProductSearchQuery,
  useProductSearchQuery,
} from '../queries/ProductSearch.delio.generated';

export const useDebouncedProductSearchQuery = (inputValue: string) => {
  const [debouncedInputValue, debouncedInputValueControl] = useDebounce(
    inputValue,
    inputValueDebounceTimeMs,
    {
      leading: false,
      trailing: true,
    }
  );

  const isInputDebouncePending = debouncedInputValue !== inputValue;

  const { coordinates, loading } = useUserAddressCoordinates();

  const isInputValueLongEnough =
    debouncedInputValue.length >= minimumInputValueLength;

  const productSearchQueryOptions = {
    skip: loading || !isInputValueLongEnough,
    onCompleted: (data: ProductSearchQuery) => {
      sendSearchQuerySetEvent({
        query: debouncedInputValue,
        sku: data.productSearch.results.map((product) => product.sku),
        attributionToken: data.productSearch.attributionToken ?? undefined,
      });
      sendSearchResultsEvent({
        searchTerm: debouncedInputValue,
        items: data.productSearch.results,
      });
    },
    variables: {
      query: debouncedInputValue,
      limit: MAX_SEARCH_PRODUCTS,
      offset: 0,
      coordinates,
    },
  };

  const {
    loading: isLoading,
    data,
    error,
  } = useProductSearchQuery(productSearchQueryOptions);

  // This value changes often. We should not use without debounce to avoid
  // flickering of the UI.
  const unstableIsLoading = isInputDebouncePending || isLoading;

  // Suppress loading state when response is quick
  const [debouncedIsLoading] = useDebounce(
    unstableIsLoading,
    inputValueDebounceTimeMs
  );

  const products = data?.productSearch.results;
  const attributionToken = data?.productSearch?.attributionToken;

  return {
    total: data?.productSearch?.total,
    attributionToken,
    products: products?.filter(Boolean),
    isErrored: Boolean(error),
    isLoading: isInputValueLongEnough && debouncedIsLoading,
    flushDebouncedInputValue: debouncedInputValueControl.flush.bind(
      debouncedInputValueControl
    ),
    isInputValueLongEnough,
  };
};

const inputValueDebounceTimeMs = 300;
const minimumInputValueLength = 3;
