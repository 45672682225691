import dynamic from 'next/dynamic';
import { useTranslation } from 'next-i18next';
import { forwardRef } from 'react';

import { useDeliveryScheduleSlots } from '@/core/hooks/useDeliveryScheduleSlots';
import { useDeliveryTime } from '@/core/hooks/useDeliveryTime';
import { useShippingAddress } from '@/core/hooks/useShippingAddress';
import { cn } from '@/core/ui/utils';
import { useStore } from '@/modules/store/hooks/useStore';
import { selectedSlotSelector } from '@/store/selectors/slotsSelectors';
import { useAppSelector } from '@/store/storeHooks';
import { DelioBrandStatus } from '@/types/gqlCommon.common.generated';
import { ShippingAddressInput } from '@/types/gqlCommon.delio.generated';

const DefaultAddressPill = dynamic(
  async () => import('../../../images/default-address-pill-icon.svg'),

  { ssr: false }
);

const NightAddressPill = dynamic(
  async () => import('../../../images/night-address-pill-icon.svg'),

  { ssr: false }
);

export const LocationButton = forwardRef<
  HTMLButtonElement,
  LocationButtonProps
>(({ onClick }, ref) => {
  const { data: address } = useShippingAddress();
  const { t } = useTranslation('location');
  const isEmpty = !address?.postalCode;
  const selectedSlot = useAppSelector(selectedSlotSelector);

  const {
    firstAvailableSlot,
    storedSlot,
    deliveryScheduleSlotsLoading,
    isStoredSlotLoading,
  } = useDeliveryScheduleSlots({});
  const { store } = useStore();

  const deliveryTime = useDeliveryTime(
    store?.status,
    selectedSlot ?? storedSlot
  );

  return (
    <button
      className={cn(
        'flex items-center gap-3 overflow-hidden rounded-lg px-3 hover:bg-surface-subtle focus:outline-none focus-visible:ring-primary-200'
      )}
      onClick={onClick}
      type="button"
      ref={ref}
    >
      <div className="min-w-6">
        {store?.status === DelioBrandStatus.AvailableNite ? (
          <NightAddressPill />
        ) : (
          <DefaultAddressPill />
        )}
      </div>

      <div className="flex h-full items-center xl:flex-col xl:items-start">
        {isEmpty ? (
          <div className="text-left font-medium">
            <p className="text-sm text-action-primary-default">
              {t('Enter delivery address')}
            </p>
            <p className="text-xs text-text-subtlest">
              {t('and start shopping')}
            </p>
          </div>
        ) : (
          <>
            <p className="line-clamp-2 max-w-[134px] text-left text-sm font-medium xl:max-w-none">
              {formatAddress(address)}
            </p>
            {!deliveryScheduleSlotsLoading && !isStoredSlotLoading && (
              <>
                {((firstAvailableSlot && deliveryTime) ||
                  !firstAvailableSlot) && (
                  <div
                    className={cn(
                      'h-3',
                      'w-[2px]',
                      'border',
                      'bg-surface-subtle',
                      'mx-3',
                      'xl:hidden'
                    )}
                  />
                )}
                <p className="text-xs font-medium text-text-subtle first-letter:capitalize">
                  {firstAvailableSlot
                    ? deliveryTime
                    : t('There are no available delivery times')}
                </p>
              </>
            )}
          </>
        )}
      </div>
    </button>
  );
});

const formatAddress = (address?: ShippingAddressInput | null) => {
  if (!address || !address.postalCode) return null;

  const streetName = String(address.streetName ?? address.city);

  const streetNumber = address.apartment
    ? `${String(address.streetNumber)}/${String(address.apartment)}`
    : String(address.streetNumber);

  return `${streetName} ${streetNumber}`;
};

type LocationButtonProps = {
  onClick?: () => void;
};
