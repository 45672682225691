import { useSession } from '@/modules/auth/hooks/useSession';

import { AccountMenuDesktopAuthenticatedContent } from './components/AccountMenuDesktopAuthenticatedContent/AccountMenuDesktopAuthenticatedContent';
import { AccountMenuDesktopPopover } from './components/AccountMenuDesktopPopover/AccountMenuDesktopPopover';
import { AccountMenuDesktopUnauthenticatedContent } from './components/AccountMenuDesktopUnauthenticatedContent/AccountMenuDesktopUnauthenticatedContent';

export const AccountMenuDesktop = () => {
  const authRole = useSession('authRole');
  const isAuthenticated = authRole === 'AUTHENTICATED';

  return (
    <div>
      <AccountMenuDesktopPopover>
        {isAuthenticated ? (
          <AccountMenuDesktopAuthenticatedContent />
        ) : (
          <AccountMenuDesktopUnauthenticatedContent />
        )}
      </AccountMenuDesktopPopover>
    </div>
  );
};
