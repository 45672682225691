// eslint-disable-next-line no-magic-numbers
type Dpr = 1 | 2 | 3;

export const transformSanityImageUrlToImageUrl = ({
  imageHeight,
  imageWidth,
  dpr,
  url,
  q = 85,
}: {
  imageWidth: number;
  imageHeight: number;
  dpr: Dpr;
  url: string;
  q?: number;
}) =>
  `${url}?w=${imageWidth}&h=${imageHeight}&fit=fillmax&dpr=${dpr}&q=${q}&auto=format`;
