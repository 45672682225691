import { TrendUp } from '@phosphor-icons/react';
import { useContextSelector } from 'use-context-selector';

import { DEFAULT_ICON_SIZES } from '@/core/constants/iconSizes';
import { cn } from '@/core/ui/utils';
import { ProductSearchContext } from '@/modules/product/context/ProductSearchContext';

type ProductSearchQueriesProps = {
  readonly queries: ReadonlyArray<string>;
  headerTitle: string;
  className?: string;
};

export const ProductSearchQueries = ({
  headerTitle,
  queries,
  className,
}: ProductSearchQueriesProps) => {
  const { combobox } = useContextSelector(
    ProductSearchContext,
    ({ combobox }) => ({
      combobox,
    })
  );

  const handleClick = (query: string) => {
    combobox.setInputValue(query);
  };

  return (
    <div className={cn(className)}>
      <h2
        className={cn(
          'text-body-md',
          'font-semibold',
          'text-text-default',
          'mb-4',
          'ml-4',
          'whitespace-nowrap'
        )}
      >
        {headerTitle}
      </h2>
      <div className={cn('flex', 'flex-col', 'gap-1')}>
        {queries.map((query) => (
          <button
            onClick={() => handleClick(query)}
            key={query}
            className={cn(
              'pl-4',
              'py-1.5',
              'flex',
              'gap-2',
              'items-center',
              'text-body-md',
              'rounded-lg',
              'transition-colors',
              'text-text-subtle',
              'hover:text-text-default',
              'hover:bg-surface-hovered'
            )}
            type="button"
          >
            <TrendUp size={DEFAULT_ICON_SIZES.sm} />
            <span>{query}</span>
          </button>
        ))}
      </div>
    </div>
  );
};
