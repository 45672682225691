import { ApolloError } from '@apollo/client';
import { useTranslation } from 'next-i18next';
import { useEffect } from 'react';
import { toast } from 'react-hot-toast';

import { useUserAddressCoordinates } from '@/core/hooks/useUserAddressCoordinates';
import { cn } from '@/core/ui/utils';
import { CategoriesQuery } from '@/modules/categories/queries/Categories.delio.generated';
import { useNavigationCategoryWithChildrenQuery } from '@/modules/categories/queries/NavigationCategoryWithChildren.delio.generated';
import { CategoryLink } from '@/modules/layout/components/CategoryLink';
import { selectL0CategoryKey } from '@/store/slices/categoriesSlice';
import { useAppDispatch } from '@/store/storeHooks';
import { SpinnerIcon } from '@lib/theme/components/SpinnerIcon';

import { NavL2Categories } from './components/NavL2Categories/NavL2Categories';

type Category = CategoriesQuery['categories']['results'][0];

export const NavL1Categories = ({
  cachedCategory,
}: {
  cachedCategory?: Category;
}) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation('layout');
  const {
    coordinates,
    loading: isLoading,
    error: addressError,
  } = useUserAddressCoordinates();

  const {
    data,
    loading,
    error: navigationError,
  } = useNavigationCategoryWithChildrenQuery(
    cachedCategory && !isLoading
      ? { variables: { categoryKey: cachedCategory.key, coordinates } }
      : { skip: true }
  );

  useEffect(() => {
    if (
      addressError &&
      addressError instanceof ApolloError &&
      // A race condition occurs when an anonymous user doesn't have a cart yet.
      // When a user logs in anonymously, the shopping cart is created in the background, while useUserAddressCooperatives is called immediately after logging in and for the second time after creating a new shopping cart.
      // The first call returns an error.
      // To check the it, take a look on SetShippingAddress hook.
      addressError.graphQLErrors.some(
        (err) => err?.extensions?.code === 'CART_NOT_FOUND'
      )
    ) {
      return;
    }

    if (navigationError || addressError) {
      toast.error(
        t('Something went wrong, please refresh the page and try again.', {
          ns: 'common',
        })
      );
    }
  }, [addressError, navigationError, t]);

  if (loading) {
    return (
      <div className={cn('flex', 'items-center', 'justify-center', 'h-full')}>
        <div
          className={cn(
            'absolute',
            'top-0',
            'left-0',
            'right-0',
            'bottom-0',
            'bg-white/50',
            'pointer-events-none',
            'z-50',
            'flex'
          )}
        >
          <SpinnerIcon
            className={cn('w-16', 'h-16', 'm-auto')}
            title={t('Loading...', {
              ns: 'common',
            })}
          />
        </div>
      </div>
    );
  }

  if (!data) {
    return null;
  }

  const l2Categories = data.category?.children.filter(
    (el) => el.productCount > 0
  );
  const l2CategoriesWithChildren = l2Categories?.filter((el) => el.childCount);
  const l2CategoriesWithoutChildren = l2Categories?.filter(
    (el) => !el.childCount
  );
  return (
    <ul
      className={cn(
        'mx-12',
        'mt-6',
        'max-w-8xl',
        'grid grid-cols-6',
        'gap-y-6'
      )}
    >
      {l2CategoriesWithChildren?.map((category) => (
        <li key={category.id}>
          {cachedCategory && (
            <CategoryLink
              l0CategoryKey={cachedCategory.key}
              l1CategoryKey={category.key}
              onClick={() => dispatch(selectL0CategoryKey(null))}
            >
              <p
                className={cn(
                  'text-lg',
                  'leading-6',
                  'font-medium',
                  'mb-2',
                  'hover:text-primary-450',
                  'text-gray-900'
                )}
              >
                {category.name}
              </p>
            </CategoryLink>
          )}

          <NavL2Categories
            cachedCategoryKey={cachedCategory?.key}
            parentCategoryKey={category.key}
            categories={category.children}
          />
        </li>
      ))}
      <li>
        <ul>
          {l2CategoriesWithoutChildren?.map((category) => (
            <li key={category.id}>
              {cachedCategory && (
                <CategoryLink
                  l0CategoryKey={cachedCategory.key}
                  l1CategoryKey={category.key}
                  onClick={() => dispatch(selectL0CategoryKey(null))}
                >
                  <p
                    className={cn(
                      'text-lg',
                      'font-medium',
                      'mb-2',
                      'hover:text-primary-450',
                      'text-gray-900'
                    )}
                  >
                    {category.name}
                  </p>
                </CategoryLink>
              )}
            </li>
          ))}
        </ul>
      </li>
    </ul>
  );
};
