import * as Types from '@/types/gqlCommon.delio.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CategoriesQueryVariables = Types.Exact<{
  limit?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  offset?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  where?: Types.InputMaybe<Types.Scalars['String']['input']>;
  coordinates?: Types.InputMaybe<Types.CoordinatesInput>;
}>;


export type CategoriesQuery = { readonly __typename: 'Query', readonly categories: { readonly __typename: 'CategoriesResult', readonly results: ReadonlyArray<{ readonly __typename: 'Category', readonly id: string, readonly key: string, readonly name: string, readonly childCount: number, readonly productCount: number, readonly backgroundColor: string, readonly webImage: { readonly __typename: 'WebCategoryImage', readonly desktopImageUrl?: string | null, readonly mobileImageUrl?: string | null }, readonly children: ReadonlyArray<{ readonly __typename: 'Category', readonly productCount: number, readonly name: string, readonly key: string, readonly id: string }> }> } };


export const CategoriesDocument = gql`
    query Categories($limit: Int, $offset: Int, $where: String, $coordinates: CoordinatesInput) {
  categories(
    limit: $limit
    offset: $offset
    where: $where
    coordinates: $coordinates
  ) {
    results {
      id
      key
      name
      childCount
      productCount
      backgroundColor
      webImage {
        desktopImageUrl
        mobileImageUrl
      }
      children {
        productCount
        name
        key
        id
      }
    }
  }
}
    `;

/**
 * __useCategoriesQuery__
 *
 * To run a query within a React component, call `useCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCategoriesQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      where: // value for 'where'
 *      coordinates: // value for 'coordinates'
 *   },
 * });
 */
export function useCategoriesQuery(baseOptions?: Apollo.QueryHookOptions<CategoriesQuery, CategoriesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CategoriesQuery, CategoriesQueryVariables>(CategoriesDocument, options);
      }
export function useCategoriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CategoriesQuery, CategoriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CategoriesQuery, CategoriesQueryVariables>(CategoriesDocument, options);
        }
export function useCategoriesSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<CategoriesQuery, CategoriesQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<CategoriesQuery, CategoriesQueryVariables>(CategoriesDocument, options);
        }
export type CategoriesQueryHookResult = ReturnType<typeof useCategoriesQuery>;
export type CategoriesLazyQueryHookResult = ReturnType<typeof useCategoriesLazyQuery>;
export type CategoriesSuspenseQueryHookResult = ReturnType<typeof useCategoriesSuspenseQuery>;
export type CategoriesQueryResult = Apollo.QueryResult<CategoriesQuery, CategoriesQueryVariables>;