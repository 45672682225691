import dynamic from 'next/dynamic';
import { ReactNode } from 'react';

import { Layout } from '../Layout';
import { NavBar } from '../NavBar';
import { NavBarBoxProvider } from '../NavBarBoxProvider';

const CartSidebar = dynamic(
  async () =>
    (await import('@/modules/cart/components/CartSidebar/CartSidebar'))
      .CartSidebar,
  { ssr: false }
);

const LocationModal = dynamic(
  async () =>
    (await import('@/modules/location/components/LocationModal/LocationModal'))
      .LocationModal,
  { ssr: false }
);

const CategoriesModal = dynamic(
  async () => (await import('../CategoriesModal')).CategoriesModal,
  { ssr: false }
);

const Footer = dynamic(async () => (await import('../Footer')).Footer, {
  ssr: false,
});
const JushAd = dynamic(async () => (await import('../JushAd/JushAd')).JushAd, {
  ssr: false,
});

const Portal = dynamic(
  async () => (await import('@/core/ui/components/Portal/Portal')).Portal,
  { ssr: false }
);

const CartProgressIndicator = dynamic(
  async () =>
    (
      await import(
        '@/core/ui/components/CartProgressIndicator/CartProgressIndicator'
      )
    ).CartProgressIndicator,
  { ssr: false }
);

export const RichLayout = ({ children }: { children: ReactNode }) => (
  <NavBarBoxProvider>
    <Layout navbar={<NavBar />} footer={<Footer />}>
      {children}
      <Portal>
        <CartProgressIndicator />
      </Portal>
      <JushAd />
      <CartSidebar />
      <LocationModal />
      <CategoriesModal />
    </Layout>
  </NavBarBoxProvider>
);
