import * as Types from '@/types/gqlCommon.delio.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type NavigationCategoryWithChildrenQueryVariables = Types.Exact<{
  categoryKey?: Types.InputMaybe<Types.Scalars['String']['input']>;
  categoryId?: Types.InputMaybe<Types.Scalars['String']['input']>;
  coordinates?: Types.InputMaybe<Types.CoordinatesInput>;
}>;


export type NavigationCategoryWithChildrenQuery = { readonly __typename: 'Query', readonly category?: { readonly __typename: 'Category', readonly backgroundColor: string, readonly id: string, readonly key: string, readonly name: string, readonly orderHint: string, readonly childCount: number, readonly productCount: number, readonly children: ReadonlyArray<{ readonly __typename: 'Category', readonly id: string, readonly key: string, readonly name: string, readonly orderHint: string, readonly childCount: number, readonly productCount: number, readonly children: ReadonlyArray<{ readonly __typename: 'Category', readonly id: string, readonly key: string, readonly name: string, readonly orderHint: string, readonly childCount: number, readonly productCount: number }> }> } | null };

export type NavigationCategoryWithChildrenPartsFragment = { readonly __typename: 'Category', readonly id: string, readonly key: string, readonly name: string, readonly orderHint: string, readonly childCount: number, readonly productCount: number };

export const NavigationCategoryWithChildrenPartsFragmentDoc = gql`
    fragment NavigationCategoryWithChildrenParts on Category {
  id
  key
  name
  orderHint
  childCount
  productCount
}
    `;
export const NavigationCategoryWithChildrenDocument = gql`
    query NavigationCategoryWithChildren($categoryKey: String, $categoryId: String, $coordinates: CoordinatesInput) {
  category(key: $categoryKey, id: $categoryId, coordinates: $coordinates) {
    backgroundColor
    ...NavigationCategoryWithChildrenParts
    children {
      ...NavigationCategoryWithChildrenParts
      children {
        ...NavigationCategoryWithChildrenParts
      }
    }
  }
}
    ${NavigationCategoryWithChildrenPartsFragmentDoc}`;

/**
 * __useNavigationCategoryWithChildrenQuery__
 *
 * To run a query within a React component, call `useNavigationCategoryWithChildrenQuery` and pass it any options that fit your needs.
 * When your component renders, `useNavigationCategoryWithChildrenQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNavigationCategoryWithChildrenQuery({
 *   variables: {
 *      categoryKey: // value for 'categoryKey'
 *      categoryId: // value for 'categoryId'
 *      coordinates: // value for 'coordinates'
 *   },
 * });
 */
export function useNavigationCategoryWithChildrenQuery(baseOptions?: Apollo.QueryHookOptions<NavigationCategoryWithChildrenQuery, NavigationCategoryWithChildrenQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<NavigationCategoryWithChildrenQuery, NavigationCategoryWithChildrenQueryVariables>(NavigationCategoryWithChildrenDocument, options);
      }
export function useNavigationCategoryWithChildrenLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<NavigationCategoryWithChildrenQuery, NavigationCategoryWithChildrenQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<NavigationCategoryWithChildrenQuery, NavigationCategoryWithChildrenQueryVariables>(NavigationCategoryWithChildrenDocument, options);
        }
export function useNavigationCategoryWithChildrenSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<NavigationCategoryWithChildrenQuery, NavigationCategoryWithChildrenQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<NavigationCategoryWithChildrenQuery, NavigationCategoryWithChildrenQueryVariables>(NavigationCategoryWithChildrenDocument, options);
        }
export type NavigationCategoryWithChildrenQueryHookResult = ReturnType<typeof useNavigationCategoryWithChildrenQuery>;
export type NavigationCategoryWithChildrenLazyQueryHookResult = ReturnType<typeof useNavigationCategoryWithChildrenLazyQuery>;
export type NavigationCategoryWithChildrenSuspenseQueryHookResult = ReturnType<typeof useNavigationCategoryWithChildrenSuspenseQuery>;
export type NavigationCategoryWithChildrenQueryResult = Apollo.QueryResult<NavigationCategoryWithChildrenQuery, NavigationCategoryWithChildrenQueryVariables>;