import { KeyboardEvent, useCallback, useEffect, useRef, useState } from 'react';

import { AUTO_FOLD_COUNTER_TIME_MS } from '@/core/constants/autoFoldTime';
import { useAddLineItemToCurrentCart } from '@/modules/cart/hooks/useAddLineItemToCurrentCart';
import { useRemoveLineItemsFromCurrentCart } from '@/modules/cart/hooks/useRemoveLineItemsFromCurrentCart';

export const useProductCounter = ({
  sku,
  attributionToken,
}: {
  sku: string;
  attributionToken?: string;
}) => {
  const [increaseProductAmount, isIncreaseLoading] =
    useAddLineItemToCurrentCart();
  const [decreaseProductAmount, { loading: isDecreaseLoading }] =
    useRemoveLineItemsFromCurrentCart();

  const timer = useRef<null | ReturnType<typeof setTimeout>>(null);
  const [isFolded, setIsFolded] = useState(true);

  const handleTimer = useCallback((isOver: boolean) => {
    if (timer.current) {
      clearTimeout(timer.current);
    }
    if (isOver) return;

    timer.current = setTimeout(() => {
      setIsFolded(true);
    }, AUTO_FOLD_COUNTER_TIME_MS);
  }, []);

  useEffect(() => handleTimer(false), [handleTimer]);

  const unFoldCounter = useCallback(() => {
    setIsFolded(false);
    handleTimer(false);
  }, [handleTimer]);

  const handleMouseOver = useCallback(() => {
    handleTimer(true);
  }, [handleTimer]);

  const handleMouseLeave = useCallback(() => {
    handleTimer(false);
  }, [handleTimer]);

  const handleKeyDown = useCallback(
    (e: KeyboardEvent<HTMLDivElement>) => {
      if (e.key === 'Enter') {
        unFoldCounter();
      }
    },
    [unFoldCounter]
  );

  const handleIncreaseProductAmount = useCallback(async () => {
    await increaseProductAmount([{ sku }], attributionToken);
    unFoldCounter();
  }, [attributionToken, increaseProductAmount, sku, unFoldCounter]);

  const handleDecreaseProductAmount = useCallback(async () => {
    await decreaseProductAmount({
      removeLineItems: [{ sku, quantity: 1 }],
      attributionToken,
    });
    unFoldCounter();
  }, [attributionToken, decreaseProductAmount, sku, unFoldCounter]);

  return {
    isFolded,
    unFoldCounter,
    handleMouseOver,
    handleMouseLeave,
    handleKeyDown,
    handleIncreaseProductAmount,
    handleDecreaseProductAmount,
    isDecreaseLoading,
    isIncreaseLoading,
  };
};
