import { useTranslation } from 'next-i18next';

import { cn } from '@/core/ui/utils';
import { useProductSearchField } from '@/modules/product/components/ProductSearch/hooks/useProductSearchField';

import { ProductSearchFieldClearButton } from '../ProductSearchFieldClearButton/ProductSearchFieldClearButton';
import { ProductSearchFieldInput } from '../ProductSearchFieldInput/ProductSearchFieldInput';
import { ProductSearchFieldLoader } from '../ProductSearchFieldLoader/ProductSearchFieldLoader';
import { ProductSearchFieldSearchButton } from '../ProductSearchFieldSearchButton/ProductSearchMobileFieldButton';

export const ProductSearchMobileField = () => {
  const { t } = useTranslation('product');
  const {
    isClearButtonVisible,
    isLoaderVisible,
    isOpen,
    labelProps,
    inputAriaAttributes,
    inputProps,
    handleClearButtonClick,
    handleSearchButtonClick,
  } = useProductSearchField();

  return (
    <label
      {...(labelProps ?? {})}
      className={cn('grow', 'min-w-[240px]', !isOpen && ['cursor-text'])}
    >
      <span className={cn('sr-only')}>{t('Search products')}</span>

      <div className={cn('flex', 'items-center', 'relative')}>
        <ProductSearchFieldInput
          {...inputProps}
          {...inputAriaAttributes}
          placeholder={t('Search products')}
          className="pr-32 md:pr-52"
        />
        <div
          className={cn('absolute', 'top-1', 'right-1', 'flex', 'items-center')}
        >
          {isLoaderVisible && (
            <div className={cn('mr-1.5')}>
              <ProductSearchFieldLoader />
            </div>
          )}

          {isClearButtonVisible && (
            <div className={cn('mr-2')}>
              <ProductSearchFieldClearButton onClick={handleClearButtonClick} />
            </div>
          )}
          <div>
            <ProductSearchFieldSearchButton
              onClick={handleSearchButtonClick}
              isOpen={isOpen}
            />
          </div>
        </div>
      </div>
    </label>
  );
};
