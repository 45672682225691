import dynamic from 'next/dynamic';

import { Media } from '@lib/theme/components/Media';

import { AnnouncementBar } from './AnnouncementBar/AnnouncementBar';
import { MainHorizontalNav } from './MainHorizontalNav/MainHorizontalNav';
import { SecondaryHorizontalNav } from './SecondaryHorizontalNav';

const PromotionNavbar = dynamic(
  async () => (await import('./PromotionNavbar')).PromotionNavbar
);

export const NavBar = () => (
  <>
    <Media greaterThanOrEqual="lg">
      <AnnouncementBar />
    </Media>
    <MainHorizontalNav />
    <SecondaryHorizontalNav />
    <Media greaterThanOrEqual="lg">
      <PromotionNavbar />
    </Media>
  </>
);
