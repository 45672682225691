import { ProductSearchBackButton } from '../components/ProductSearchBackButton/ProductSearchBackButton';
import { ProductSearchMobileContainer } from '../components/ProductSearchMobileContainer/ProductSearchMobileContainer';
import { ProductSearchMobileField } from '../components/ProductSearchMobileField/ProductSearchMobileField';
import { ProductSearchMobileOverlay } from '../components/ProductSearchMobileOverlay/ProductSearchMobileOverlay';
import { ProductSearchProvider } from '../ProductSearchProvider';

import { ProductSearchMobileMenu } from './components/ProductSearchMobileMenu/ProductSearchMobileMenu';

export const ProductSearchMobile = () => (
  <ProductSearchProvider>
    <ProductSearchMobileOverlay>
      <ProductSearchMobileContainer>
        <ProductSearchBackButton />
        <ProductSearchMobileField />
      </ProductSearchMobileContainer>
      <ProductSearchMobileMenu />
    </ProductSearchMobileOverlay>
  </ProductSearchProvider>
);
