import { useTranslation } from 'next-i18next';

import { cn } from '@/core/ui/utils';
import { Media } from '@lib/theme/components/Media';

type ProductEmptyStateHeaderProps = {
  query: string;
  isSearch: boolean;
};

export const ProductEmptyStateHeader = ({
  isSearch,
  query,
}: ProductEmptyStateHeaderProps) => {
  const { t } = useTranslation('product');

  return (
    <>
      <Media greaterThanOrEqual="lg">
        <HeaderWithQuery query={query} isSearch={isSearch} />
      </Media>
      <Media lessThan="lg">
        {isSearch ? (
          <h2
            className={cn('text-title-sm', 'font-medium', 'text-text-default')}
          >
            {t('No results')}
          </h2>
        ) : (
          <HeaderWithQuery query={query} isSearch={isSearch} />
        )}
      </Media>
    </>
  );
};

type HeaderWithQueryProps = {
  query: string;
  isSearch?: boolean;
};

const HeaderWithQuery = ({ query, isSearch }: HeaderWithQueryProps) => {
  const { t } = useTranslation('product');

  return (
    <h2
      className={cn(
        'text-title-md',
        'font-medium',
        'text-text-default',
        'break-words',
        isSearch ? 'lg:text-title-sm' : 'lg:text-heading-large'
      )}
    >
      {t('No results for')}{' '}
      <span className="text-text-highlighted">{query}</span>
    </h2>
  );
};
