import { MagnifyingGlass } from '@phosphor-icons/react';
import { useTranslation } from 'next-i18next';

import { DEFAULT_ICON_SIZES } from '@/core/constants/iconSizes';
import { Button } from '@/core/ui/components/Button/Button';
import { Media } from '@lib/theme/components/Media';

type ProductSearchFieldSearchButtonProps = {
  onClick?: () => void;
  isOpen?: boolean;
};

export const ProductSearchFieldSearchButton = ({
  onClick,
  isOpen,
}: ProductSearchFieldSearchButtonProps) => {
  const { t } = useTranslation('product');
  return (
    <>
      <Media lessThan="md">
        <Button
          data-testid="search-button"
          onClick={onClick}
          icon={<MagnifyingGlass size={DEFAULT_ICON_SIZES.md} />}
        />
      </Media>
      <Media greaterThanOrEqual="md">
        <Button
          data-testid="search-button"
          onClick={onClick}
          leftIcon={isOpen && <MagnifyingGlass size={DEFAULT_ICON_SIZES.md} />}
          icon={!isOpen && <MagnifyingGlass size={DEFAULT_ICON_SIZES.md} />}
        >
          {isOpen && t('Search')}
        </Button>
      </Media>
    </>
  );
};
