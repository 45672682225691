import { analytics } from '@/config/analytics';
import { CENTS_PER_UNIT } from '@/core/constants/price';
import { pushGtmEvent } from '@/core/services/pushGtmEvent';
import { CurrentCart } from '@/core/types';

import { createGtmItem } from '../services/createGtmItem';

export const sendCartViewedEvent = (cart: CurrentCart) => {
  const payload: CartViewedSegmentPayload = {
    cart_id: cart.id,
    brand: 'delio',
    version: '0.3',
    products: cart.lineItems.map(({ product, quantity }) => {
      const discountCentAmount = product.price.discounted?.value.centAmount;

      return {
        product_id: product.id,
        sku: product.sku,
        name: product.name,
        price: product.price.value.centAmount / CENTS_PER_UNIT,
        currency: product.price.value.currencyCode,
        price_discounted:
          discountCentAmount && discountCentAmount >= 0
            ? discountCentAmount / CENTS_PER_UNIT
            : undefined,
        quantity,
        url: window.location.href,
        image_url: product.imagesUrls[0],
      };
    }),
  };

  pushGtmEvent({
    event: 'view_cart',
    value: cart.totalPrice.centAmount / CENTS_PER_UNIT,
    currency: cart.totalPrice.currencyCode,
    items: cart.lineItems.map((lineItem) =>
      createGtmItem(lineItem.product, lineItem.quantity)
    ),
    brand: 'delio',
  });

  return analytics?.track('Cart Viewed', payload);
};

export type CartViewedSegmentPayload = {
  cart_id: string;
  brand: string;
  version: string;
  products: {
    product_id: string;
    sku?: string | null;
    name?: string;
    price: number;
    price_discounted?: number;
    url?: string;
  }[];
};
