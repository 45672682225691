import { Trans, useTranslation } from 'next-i18next';

import { cn } from '@/core/ui/utils';
import { FC } from '@/types/common';

import { ProductSearchEmptyState } from '../../../components/ProductSearchEmptyState/ProductSearchEmptyState';

import { MobileSuggestedProduct } from './components/MobileSuggestedProduct/MobileSuggestedProduct';
import { ProductSearchMobileMenuPills } from './components/ProductSearchMobileMenuPills/ProductSearchMobileMenuPills';
import { ProductSearchMobileMenuSkeleton } from './components/ProductSearchMobileMenuSkeleton/ProductSearchMobileMenuSkeleton';
import { useProductSearchMobileMenu } from './hooks/useProductSearchMobileMenu';

export const ProductSearchMobileMenu: FC = () => {
  const { t } = useTranslation('product');
  const {
    pills,
    isLoading,
    isOpen,
    isEmpty,
    searchDefaults,
    comboboxItems,
    total,
    hasMoreSuggestedProducts,
    isErrored,
    pushToSearchResults,
    closeSearch,
  } = useProductSearchMobileMenu();

  if (!isOpen) return <ul />;

  if (isLoading) {
    return <ProductSearchMobileMenuSkeleton />;
  }

  if (isEmpty || !searchDefaults || isErrored) {
    return (
      <ul>
        <li>
          <ProductSearchEmptyState />
        </li>
      </ul>
    );
  }

  const handleClick = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    await pushToSearchResults();
    closeSearch();
  };

  return (
    <div className={cn('overflow-y-auto')}>
      {!total && (
        <ProductSearchMobileMenuPills
          pills={pills}
          title={searchDefaults?.combinedQueriesTitle}
        />
      )}
      <div
        className={cn(
          'text-body-lg',
          'font-medium',
          'text-text-default',
          'ml-4',
          'mb-2',
          'mt-6'
        )}
      >
        {total ? (
          <Trans
            ns="product"
            i18nKey="Found products <Gray>({{total}})</Gray>"
            components={{
              Gray: <span className={cn('font-medium', 'text-text-subtle')} />,
            }}
            values={{ total }}
          />
        ) : (
          searchDefaults?.productsTitle
        )}
      </div>
      <ul>
        {comboboxItems.map((item, index) => (
          <MobileSuggestedProduct index={index} key={item.key} />
        ))}
      </ul>
      {hasMoreSuggestedProducts && (
        <button
          type="button"
          onClick={handleClick}
          className={cn(
            'pt-6',
            'pb-2',
            'text-center',
            'text-body-md',
            'font-medium',
            'text-interactive-default',
            'w-full'
          )}
        >
          {t('See all products')}
        </button>
      )}
    </div>
  );
};
