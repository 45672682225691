import { useState } from 'react';
import { useContextSelector } from 'use-context-selector';

import { cn } from '@/core/ui/utils';
import { LineItemsCountBadge } from '@/modules/cart/components/LineItemsCountBadge';
import { LocationPill } from '@/modules/layout/components/LocationPill/LocationPill';
import { ProductSearchDesktop } from '@/modules/product/components/ProductSearch/components/ProductSearchDesktop/ProductSearchDesktop';
import { ProductSearchMobile } from '@/modules/product/components/ProductSearch/components/ProductSearchMobile/ProductSearchMobile';
import { Media } from '@lib/theme/components/Media';

import { NavBarBoxContext } from '../../contexts/NavBarBoxContext';

import { AccountMenu } from './components/AccountMenu/AccountMenu';
import { FavouritesButton } from './components/FavouritesButton/FavouritesButton';
import { LogoLink } from './components/LogoLink/LogoLink';
import { MobileCategoriesMenu } from './components/MobileCategoriesMenu/MobileCategoriesMenu';
import { YourCartLink } from './components/YourCartButton/YourCartButton';

export const MainHorizontalNav = () => {
  const { navBarRef } = useContextSelector(
    NavBarBoxContext,
    ({ navBarRef }) => ({
      navBarRef,
    })
  );

  return (
    <div
      // if you change this contents property check if navbar is sticky to the top on mobile
      className="top-0 z-30 contents bg-white xl:sticky xl:flex xl:border-b xl:border-border-subtle"
      ref={navBarRef}
    >
      <nav className="mx-auto contents w-full max-w-8xl flex-1 flex-col xl:flex xl:flex-row xl:items-center xl:px-4">
        <Media lessThan="xl">
          <MobileNav />
        </Media>
        <Media greaterThanOrEqual="xl">
          <DesktopNav />
        </Media>
      </nav>
    </div>
  );
};

const DesktopNav = () => {
  const [isSearchOpen, openSearch] = useState(false);

  const onIsOpenChange = (open?: boolean) => {
    if (open) {
      openSearch(true);
    } else {
      openSearch(false);
    }
  };

  return (
    <>
      <div className="flex h-[88px] items-center justify-between border-b border-border-subtle xl:border-none">
        <div className="h-12">
          <LogoLink />
        </div>
      </div>

      <div className="mx-4 flex items-center justify-center whitespace-nowrap">
        <div className={cn(!isSearchOpen ? 'min-w-[186px]' : 'max-w-[136px]')}>
          <LocationPill />
        </div>
      </div>

      {/* if you change this div check if opened search renders correctly */}
      <div className="flex h-12 grow">
        <ProductSearchDesktop onIsOpenChange={onIsOpenChange} />
      </div>
      <div className="ml-[30px] flex xl:gap-4">
        <FavouritesButton />
        <AccountMenu />
        <LineItemsCountBadge>
          <YourCartLink />
        </LineItemsCountBadge>
      </div>
    </>
  );
};

const MobileNav = () => (
  <>
    <div className="flex h-16 items-center justify-between border-b border-border-subtle pl-[11px]">
      <div className="h-12">
        <LogoLink />
      </div>
      <div className="flex">
        <FavouritesButton />
        <AccountMenu />
        <LineItemsCountBadge>
          <YourCartLink />
        </LineItemsCountBadge>
      </div>
    </div>

    <div className="mx-4 flex h-[60px] items-center overflow-hidden">
      <div className="h-9 w-full">
        <LocationPill />
      </div>
    </div>

    <div className="sticky top-0 z-30 bg-white">
      <div className="mt-2 flex flex-1 border-b border-border-subtle pb-4 pl-2 pr-4">
        <MobileCategoriesMenu />
        <ProductSearchMobile />
      </div>
    </div>
  </>
);
