import { cn } from '@/core/ui/utils';

type ProductMultibuyBadgeProps = {
  description: string;
  variant?: 'primary' | 'secondary';
};

export const ProductMultibuyBadge = ({
  description,
  variant = 'primary',
}: ProductMultibuyBadgeProps) => (
  <div
    className={cn(
      variant === 'primary'
        ? 'bg-badge-multibuy'
        : 'bg-decorative-surface-three',
      'py-1',
      'px-2.5',
      'rounded-3xl',
      'font-medium',
      'text-white',
      'text-body-sm',
      'inline-flex',
      'items-center',
      'justify-center'
    )}
  >
    {description}
  </div>
);
