import { useTranslation } from 'next-i18next';

import { cn } from '@/core/ui/utils';
import { FC } from '@/types/common';

import { ProductSearchBrands } from '../ProductSearchBrands/ProductSearchBrands';
import { ProductSearchEmptyState } from '../ProductSearchEmptyState/ProductSearchEmptyState';
import { ProductSearchQueries } from '../ProductSearchQueries/ProductSearchQueries';
import { ProductSearchSuggestedProduct } from '../ProductSearchSuggestedProduct/ProductSearchSuggestedProduct';

import { ProductSearchMenuHeader } from './components/ProductSearchMenuHeader/ProductSearchMenuHeader';
import { ProductSearchMenuSkeleton } from './components/ProductSearchMenuSkeleton/ProductSearchMenuSkeleton';
import { useProductSearchMenu } from './hooks/useProductSearchMenu';

export const ProductSearchMenu: FC = () => {
  const { t } = useTranslation('product');
  const {
    isEmpty,
    isOpen,
    searchDefaults,
    hasMoreSuggestedProducts,
    query,
    shouldShowSkeleton,
    suggestedProducts,
    total,
    isErrored,
    pushToSearchResults,
    closeSearch,
  } = useProductSearchMenu();

  if (!isOpen) return <ul />;

  const handleClick = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    await pushToSearchResults();
    closeSearch();
  };

  if (isEmpty || !searchDefaults || isErrored) {
    return (
      <ul>
        <li>
          <ProductSearchEmptyState query={query} />
        </li>
      </ul>
    );
  }

  return (
    <div
      className={cn(
        'flex',
        'gap-x-8',
        'overflow-y-auto',
        'lg:flex-col',
        'xl:flex-row'
      )}
    >
      <div
        className={cn(
          'flex',
          'flex-col',
          'max-w-[292px]',
          'w-full',
          'py-6',
          'pl-2',
          'gap-y-5',
          'lg:flex-row',
          'lg:max-w-full',
          'lg:pr-6',
          'lg:gap-x-4',
          'lg:py-5',
          'xl:flex-col',
          'xl:max-w-[292px]',
          'xl:pr-0',
          'xl:gap-x-0',
          'xl:py-6'
        )}
      >
        <ProductSearchQueries
          headerTitle={searchDefaults.queriesTitle}
          queries={searchDefaults.queries}
        />
        <ProductSearchBrands
          headerTitle={searchDefaults.brandsTitle}
          brands={searchDefaults.brands}
        />
      </div>
      <div
        className={cn(
          'w-full',
          'pr-6',
          'pt-6',
          'lg:pt-0',
          'lg:pl-6',
          'xl:pt-6',
          'xl:pl-0'
        )}
      >
        {shouldShowSkeleton ? (
          <ProductSearchMenuSkeleton />
        ) : (
          <>
            <ProductSearchMenuHeader
              productsTitle={searchDefaults.productsTitle}
              total={total}
            />
            <ul className={cn('flex', 'flex-col', 'gap-3', 'mt-5')}>
              {suggestedProducts.map((item, index) => (
                <ProductSearchSuggestedProduct key={item.key} index={index} />
              ))}
            </ul>
            {hasMoreSuggestedProducts && (
              <button
                type="button"
                onClick={handleClick}
                className={cn(
                  'w-full',
                  'text-body-md',
                  'text-text-default',
                  'font-medium',
                  'pb-2',
                  'pt-6',
                  'text-center'
                )}
              >
                {t('See all products')}
              </button>
            )}
          </>
        )}
      </div>
    </div>
  );
};
