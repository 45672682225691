import { useContextSelector } from 'use-context-selector';

import { useHideChat } from '@/core/hooks/useHideChat';
import { cn } from '@/core/ui/utils';
import { ProductSearchContext } from '@/modules/product/context/ProductSearchContext';
import { FCC } from '@/types/common';

export const ProductSearchMobileOverlay: FCC = ({ children }) => {
  const { isOpen, menuProps } = useContextSelector(
    ProductSearchContext,
    ({ combobox }) => combobox
  );

  useHideChat(isOpen);

  const comboboxContainerTopOffset = 0;

  return (
    <div
      // If want to avoid closing when clicking outside the combobox, let's
      // just include the overlay to the combobox clicking area, by providing
      // the combobox ref to the overlay.
      className={cn(
        isOpen
          ? [
              'fixed',
              'inset-0',
              'z-20',
              'flex',
              'transition-opacity',
              'transition-colors',
              'bg-white',
            ]
          : ['flex', 'flex-col', 'transition-none', 'grow', 'h-full']
      )}
    >
      <div
        style={
          isOpen
            ? {
                // This allows us to calculate the correct product search position when the user scrolls the page,
                // and also protects us from possible padding size changes
                paddingTop: comboboxContainerTopOffset,
              }
            : {}
        }
        className={cn(
          isOpen
            ? ['mx-auto', 'mb-auto', 'w-full', 'flex', 'max-h-full']
            : ['flex', 'grow']
        )}
      >
        <ul
          className={cn(
            'bg-white',
            'flex',
            'flex-col',
            'grow',
            'rounded-xl',
            'relative',
            // Bottom padding is necessary to render rounded corners correctly.
            // Otherwise, the content may overflow and the corners might be cut
            // in angular shape.
            isOpen && cn('pb-4', 'overflow-hidden', 'md:overflow-auto')
          )}
          {...menuProps}
        >
          {children}
        </ul>
      </div>
    </div>
  );
};
