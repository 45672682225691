import throttle from 'just-throttle';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useDebounce } from 'use-debounce';

export const useWindowScrollDirection = ({
  throttleDelayMs = 50,
  onAfterScroll,
}: UseWindowScrollDirectionParam) => {
  const prevWindowScrollY = useRef<number | null>(null);
  const prevWindowScrollX = useRef<number | null>(null);
  const [direction, setDirection] = useState<ScrollDirection>(null);

  const handleWindowScroll = useCallback(() => {
    const currentWindowScrollY = Math.round(window.pageYOffset);
    const currentWindowScrollX = Math.round(window.pageXOffset);

    const newDirection = computeScrollDirection({
      currentWindowScrollY,
      currentWindowScrollX,
      prevWindowScrollY: prevWindowScrollY.current ?? currentWindowScrollY,
      prevWindowScrollX: prevWindowScrollX.current ?? currentWindowScrollX,
    });

    setDirection(newDirection);

    prevWindowScrollY.current = currentWindowScrollY;
    prevWindowScrollX.current = currentWindowScrollX;
  }, []);

  const handleAfterScroll = useCallback(() => {
    if (!onAfterScroll) return;
    onAfterScroll({
      direction,
      setDirection,
      prevWindowScrollX: prevWindowScrollX.current ?? 0,
      prevWindowScrollY: prevWindowScrollY.current ?? 0,
    });
  }, [direction, onAfterScroll]);

  useEffect(() => {
    const handler = throttle(
      () => {
        handleWindowScroll();
        handleAfterScroll();
      },
      throttleDelayMs,
      {
        leading: false,
        trailing: true,
      }
    );

    window.addEventListener('scroll', handler, {
      passive: true,
    });

    return () => {
      handler.cancel();
      return window.removeEventListener('scroll', handler);
    };
  }, [handleWindowScroll, throttleDelayMs, handleAfterScroll]);

  // Additional debounce to avoid "ping-pong" effect, when scrolling slowly
  // eslint-disable-next-line no-magic-numbers
  const [debouncedDirection] = useDebounce(direction, throttleDelayMs * 2);
  return debouncedDirection;
};

const computeScrollDirection = ({
  currentWindowScrollY,
  currentWindowScrollX,
  prevWindowScrollY,
  prevWindowScrollX,
}: {
  currentWindowScrollY: number;
  currentWindowScrollX: number;
  prevWindowScrollY: number;
  prevWindowScrollX: number;
}) => {
  if (currentWindowScrollY === 0 && currentWindowScrollX === 0) return null;

  if (
    currentWindowScrollY === prevWindowScrollY &&
    currentWindowScrollX === prevWindowScrollX
  ) {
    return null;
  }

  if (currentWindowScrollX === prevWindowScrollX) {
    return prevWindowScrollY < currentWindowScrollY ? 'down' : 'up';
  }

  return prevWindowScrollX < currentWindowScrollX ? 'right' : 'left';
};

type ScrollDirection = 'up' | 'down' | 'left' | 'right' | null;

type UseWindowScrollDirectionParam = {
  throttleDelayMs?: number;
  onAfterScroll?: (config: {
    prevWindowScrollY: number;
    prevWindowScrollX: number;
    direction: ScrollDirection;
    setDirection: (newDirection: ScrollDirection) => void;
  }) => void;
};
