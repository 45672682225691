import NextLink from 'next/link';

import { cn } from '@/core/ui/utils';
import { FCC } from '@/types/common';

import {
  createCategoryHref,
  CreateCategoryHrefParam,
} from '../../categories/factories/createCategoryHref';

export const CategoryLink: FCC<
  CreateCategoryHrefParam & {
    scroll?: boolean;
    width?: 'w-full';
    onClick?: () => void;
  }
> = ({ children, scroll = true, width, onClick, ...props }) => {
  const href = createCategoryHref(props);
  return (
    <NextLink
      href={href}
      scroll={scroll}
      shallow
      className={cn('inline-block', width)}
      onClick={onClick}
    >
      {children}
    </NextLink>
  );
};
