import Link from 'next/link';
import { useTranslation } from 'next-i18next';

import { cn } from '@/core/ui/utils';

import { Logo } from '../../../Logo';

export const LogoLink = () => {
  const { t } = useTranslation('layout');

  return (
    <Link
      href="/"
      data-testid="delio-home-logo"
      className={cn(
        'text-primary-500',
        'focus:outline-none',
        'block',
        'w-full',
        'h-full'
      )}
    >
      <Logo title={t('Delio supermarket - logo')} className="h-full w-auto" />
    </Link>
  );
};
