import { MutableRefObject, RefObject } from 'react';
import { createContext } from 'use-context-selector';

export const NavBarBoxContext = createContext<NavBarBoxContextType>({
  get navBarBottomPosition(): never {
    throw missingNavBarBoxContextError;
  },
  get navBarRightPosition(): never {
    throw missingNavBarBoxContextError;
  },
  get navBarPaddingRight(): never {
    throw missingNavBarBoxContextError;
  },
  get navBarRef(): never {
    throw missingNavBarBoxContextError;
  },
  get secondaryNavBarBottomPosition(): never {
    throw missingNavBarBoxContextError;
  },
  get secondaryNavBarRef(): never {
    throw missingNavBarBoxContextError;
  },
  get promotionsNavBarRef(): never {
    throw missingNavBarBoxContextError;
  },
});

const missingNavBarBoxContextError = new Error(
  'NavBarBoxContext is not provided'
);

type NavBarBoxContextType = {
  navBarRef: MutableRefObject<HTMLDivElement | null>;
  secondaryNavBarRef: RefObject<HTMLDivElement>;
  promotionsNavBarRef: RefObject<HTMLDivElement>;
} & NavBarBoxValues;

export type NavBarBoxValues = {
  navBarBottomPosition: number | undefined;
  navBarRightPosition: number | undefined;
  navBarPaddingRight: number | undefined;
  secondaryNavBarBottomPosition: number | undefined;
};
