import { useContextSelector } from 'use-context-selector';

import { ProductSearchContext } from '@/modules/product/context/ProductSearchContext';

export const useProductSearchField = () => {
  const {
    combobox: { isOpen, labelProps, inputProps, inputAriaAttributes, reset },
    pushToSearchResultsStatus,
    isLoading: isSearchLoading,
    pushToSearchResults,
  } = useContextSelector(
    ProductSearchContext,
    ({
      combobox,
      pushToSearchResultsStatus,
      isLoading,
      pushToSearchResults,
    }) => ({
      combobox,
      pushToSearchResultsStatus,
      isLoading,
      pushToSearchResults,
    })
  );

  const isClearButtonVisible = Boolean(isOpen && inputProps?.value);
  const isLoaderVisible =
    pushToSearchResultsStatus === 'LOADING' || isSearchLoading;

  const handleClearButtonClick = () => reset();

  const handleSearchButtonClick = isOpen ? pushToSearchResults : undefined;

  return {
    labelProps,
    isOpen,
    isClearButtonVisible,
    isLoaderVisible,
    inputProps,
    inputAriaAttributes,
    handleClearButtonClick,
    handleSearchButtonClick,
  };
};
