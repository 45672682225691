import { useTranslation } from 'next-i18next';

import { cn } from '@/core/ui/utils';
import { useProductSearchField } from '@/modules/product/components/ProductSearch/hooks/useProductSearchField';

import { ProductSearchFieldClearButton } from '../../../components/ProductSearchFieldClearButton/ProductSearchFieldClearButton';
import { ProductSearchFieldInput } from '../../../components/ProductSearchFieldInput/ProductSearchFieldInput';
import { ProductSearchFieldLoader } from '../../../components/ProductSearchFieldLoader/ProductSearchFieldLoader';
import { ProductSearchFieldSearchButton } from '../../../components/ProductSearchFieldSearchButton/ProductSearchMobileFieldButton';

export const ProductSearchDesktopField = () => {
  const { t } = useTranslation('product');
  const {
    isClearButtonVisible,
    isLoaderVisible,
    isOpen,
    labelProps,
    inputAriaAttributes,
    inputProps,
    handleClearButtonClick,
    handleSearchButtonClick,
  } = useProductSearchField();

  return (
    <label
      {...(labelProps ?? {})}
      className={cn(
        'grow',
        'relative',
        !isOpen && ['cursor-text', 'min-w-[240px]'],
        isOpen && ['min-w-[400px]', 'px-2']
      )}
    >
      <div className="absolute -left-8 -top-5 h-[88px] w-8 bg-gradient-to-l from-white" />
      <span className={cn('sr-only')}>{t('Search products')}</span>

      <div className={cn('flex', 'items-center', 'relative')}>
        <ProductSearchFieldInput
          {...inputProps}
          {...inputAriaAttributes}
          placeholder={t('Search products')}
          className={cn(isOpen ? 'pr-52' : 'pr-14')}
        />
        <div
          className={cn('absolute', 'top-1', 'right-1', 'flex', 'items-center')}
        >
          {isLoaderVisible && (
            <div className={cn('mr-1.5')}>
              <ProductSearchFieldLoader />
            </div>
          )}

          {isClearButtonVisible && (
            <div className={cn('mr-2')}>
              <ProductSearchFieldClearButton onClick={handleClearButtonClick} />
            </div>
          )}
          <div>
            <ProductSearchFieldSearchButton
              onClick={handleSearchButtonClick}
              isOpen={isOpen}
            />
          </div>
        </div>
      </div>
    </label>
  );
};
