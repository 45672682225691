import { FC, memo } from 'react';

import { BundleBadge } from '@/core/ui/components/BundleBadge/BundleBadge';
import { FormattedNetContain } from '@/core/ui/components/FormattedNetContain/FormattedNetContain';
import { ProductImage } from '@/core/ui/components/ProductImage/ProductImage';
import { ProductMultibuyBadge } from '@/core/ui/components/ProductMultibuyBadge/ProductMultibuyBadge';
import { cn } from '@/core/ui/utils';
import { ProductCounter } from '@/modules/product/components/ProductCounter/ProductCounter';

import { ProductSearchSuggestedProductBadges } from './components/ProductSearchSuggestedProductBadges/ProductSearchSuggestedProductBadges';
import { ProductSearchSuggestedProductPrice } from './components/ProductSearchSuggestedProductPrice/ProductSearchSuggestedProductPrice';
import { useProductSearchSuggestedProduct } from './hooks/useProductSearchSuggestedProduct';

type SuggestedProductProps = {
  index: number;
};

export const ProductSearchSuggestedProduct: FC<SuggestedProductProps> = memo(
  ({ index }) => {
    const {
      hasBadges,
      imageUrl,
      product,
      pushToProductPage,
      attributionToken,
      discount,
      pricePercentageDifference,
      isOnStock,
    } = useProductSearchSuggestedProduct(index);

    if (!product) return null;

    return (
      <li
        className={cn(
          'h-[92px]',
          'border',
          'border-border-subtle',
          'rounded-lg',
          'box-content'
        )}
      >
        <div className={cn('flex', 'items-center', 'relative', 'h-full')}>
          <button
            onClick={() => pushToProductPage(index)}
            className={cn('flex', 'size-full', 'pt-3', 'pb-2')}
            type="button"
          >
            <div
              className={cn(
                'min-w-12',
                'h-12',
                'mr-3',
                'ml-4',
                'self-center',
                !isOnStock && 'grayscale'
              )}
            >
              <ProductImage alt={product.name} url={imageUrl} />
            </div>
            <div
              className={cn(
                'flex',
                'flex-col',
                'w-full',
                'self-center',
                'mr-2'
              )}
            >
              <span
                className={cn(
                  'line-clamp-2',
                  'text-body-lg',
                  'font-medium',
                  'text-left',
                  'h-10',
                  !isOnStock && 'text-text-disabled'
                )}
              >
                {product.name}
              </span>
              <div
                className={cn(
                  'flex',
                  'text-text-subtlest',
                  'text-body-sm',
                  'items-center',
                  hasBadges ? 'mt-1' : 'mt-2'
                )}
              >
                <FormattedNetContain
                  containUnit={product.attributes.contain_unit}
                  netContain={product.attributes.net_contain}
                />
                <div className={cn('flex', 'gap-x-1.5', 'ml-2')}>
                  {isOnStock && product?.price?.multibuy?.description && (
                    <ProductMultibuyBadge
                      variant={
                        product?.price?.multibuy?.maxQuantity
                          ? 'secondary'
                          : 'primary'
                      }
                      description={product.price.multibuy.description}
                    />
                  )}
                  {product.productType === 'complex-bundle' && (
                    <BundleBadge disabled={!isOnStock} />
                  )}
                  <ProductSearchSuggestedProductBadges
                    disabled={!isOnStock}
                    priceDifference={pricePercentageDifference}
                    tags={product.attributes.tags}
                  />
                </div>
              </div>
            </div>
            <div className="h-full">
              <ProductSearchSuggestedProductPrice
                discount={discount}
                productPrice={product?.price?.value}
              />
            </div>
          </button>
          <div className={cn('ml-3', 'mr-4')}>
            <ProductCounter
              isPublished={product.isPublished}
              availableQuantity={product.availableQuantity}
              sku={product.sku}
              attributionToken={attributionToken}
            />
          </div>
        </div>
      </li>
    );
  }
);

ProductSearchSuggestedProduct.displayName = 'ProductSearchSuggestedProduct';
