import { Minus, Plus } from '@phosphor-icons/react';
import { MouseEvent } from 'react';

import { DEFAULT_ICON_SIZES } from '@/core/constants/iconSizes';
import { cn } from '@/core/ui/utils';
import { SpinnerIcon } from '@lib/theme/components/SpinnerIcon';

type ProductCounterButtonProps = {
  variant: 'increase' | 'decrease';
  onClick: (e: MouseEvent<HTMLButtonElement>) => void;
  title: string;
  loadingIndicatorTitle: string;
  disabled?: boolean;
  testId?: string;
  isLoading?: boolean;
  className?: string;
};

export const ProductCounterButton = ({
  onClick,
  title,
  disabled,
  testId,
  isLoading,
  loadingIndicatorTitle,
  variant,
  className,
}: ProductCounterButtonProps) => {
  const handleClick = (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    onClick(e);
  };

  return (
    <button
      type="button"
      onClick={handleClick}
      disabled={disabled || isLoading}
      title={title}
      data-testid={testId}
      className={cn(
        'w-9',
        'h-9',
        'bg-white',
        'rounded-full',
        'flex',
        'items-center',
        'justify-center',
        disabled ? 'text-icon-disabled' : 'text-icon-default',
        className
      )}
    >
      {isLoading ? (
        <SpinnerIcon
          className={cn('w-5', 'h-5', 'flex')}
          title={loadingIndicatorTitle}
        />
      ) : (
        <div>
          {variant === 'increase' ? (
            <Plus size={DEFAULT_ICON_SIZES.sm} weight="bold" />
          ) : (
            <Minus size={DEFAULT_ICON_SIZES.sm} weight="bold" />
          )}
        </div>
      )}
    </button>
  );
};
