import { X as CloseIcon } from '@phosphor-icons/react';
import dynamic from 'next/dynamic';
import Link from 'next/link';
import { useTranslation } from 'next-i18next';

import { mobileAppUrls } from '@/core/constants/appUrls';
import { DEFAULT_ICON_SIZES } from '@/core/constants/iconSizes';
import AndroidIcon from '@/core/images/android-icon.svg';
import AppleIcon from '@/core/images/apple-icon.svg';
import { Dialog } from '@lib/theme/components/Dialog';
import { useIsIos } from '@lib/theme/hooks/useIsIos';
import { useIsSafariBrowser } from '@lib/theme/hooks/useIsSafariBrowser';

import { AccountMenuAuthButtons } from '../../../AccountMenuAuthButtons/AccountMenuAuthButtons';

const EmptyCart = dynamic(async () => import('@/core/images/empty-cart.svg'), {
  ssr: false,
});

type AccountMenuMobileDialogProps = {
  open: boolean;
  onClose: () => void;
};

const iconClasses = 'w-4 h-4';

export const AccountMenuMobileDialog = ({
  open,
  onClose,
}: AccountMenuMobileDialogProps) => {
  const { t } = useTranslation('layout');
  const isIos = useIsIos();
  const isSafari = useIsSafariBrowser();
  const isApple = isIos || isSafari;

  return (
    <Dialog open={open} onClose={onClose}>
      <div className="flex h-full flex-col overflow-auto px-4 pb-6 pt-4">
        <button
          aria-label="Close dialog"
          onClick={onClose}
          type="button"
          className="mb-6 ml-auto flex size-6 items-center justify-center"
        >
          <CloseIcon size={DEFAULT_ICON_SIZES.lg} />
        </button>

        <div className="mt-auto text-center">
          <EmptyCart className="mx-auto w-full max-w-[172px]" />

          <h3 className="mb-2 mt-6 text-title-sm font-medium text-text-default">
            {t(
              'Create a delio account to take full advantage of our application'
            )}
          </h3>
          <p className="text-body-md text-text-subtle">
            {t('Click the button below to log in or create an account.')}
          </p>
          <div className="mb-11 mt-10">
            <AccountMenuAuthButtons />
          </div>
          <div className="-ml-4 mb-6 h-px w-[calc(100%+32px)] border-t border-border-subtle" />
          <p className="text-body-sm">
            {t(
              'Do your shopping quickly and conveniently on the Żabka Jush app!'
            )}
          </p>
          <Link
            target="_blank"
            rel="noreferrer"
            href={isApple ? mobileAppUrls.iphone : mobileAppUrls.android}
            className="mx-auto mt-3 flex max-w-fit items-center justify-center gap-1.5 rounded-full bg-surface-neutral-default px-3 py-2"
          >
            <div className="text-text-subtle">
              {isApple ? (
                <AppleIcon data-testid="apple-icon" className={iconClasses} />
              ) : (
                <AndroidIcon
                  data-testid="android-icon"
                  className={iconClasses}
                />
              )}
            </div>
            <p className="text-body-sm font-medium">
              {t(isApple ? 'iPhone' : 'Android')}
            </p>
          </Link>
        </div>
      </div>
    </Dialog>
  );
};
