import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';

import { AUTH_ROLE } from '@/modules/auth/contexts/AuthContext';
import { useSession } from '@/modules/auth/hooks/useSession';
import HeartIcon from '@lib/theme/svg/heart-icon.svg';

import { IconButton } from '../../../IconButton/IconButton';

export const FavouritesButton = () => {
  const { t } = useTranslation('layout');
  const authRole = useSession('authRole');
  const { push } = useRouter();

  if (authRole !== AUTH_ROLE.AUTHENTICATED) {
    return null;
  }

  const onClick = () => {
    push('/favourites');
  };

  return (
    <IconButton onClick={onClick}>
      <HeartIcon className="size-6" />
      <p className="hidden text-sm font-medium xl:block">{t('Favourites')}</p>
    </IconButton>
  );
};
