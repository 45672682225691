import { logger } from '@/config/logger';
import { useCurrentCart } from '@/core/hooks/useCurrentCart';
import { cn } from '@/core/ui/utils';
import { selectTotalQuantityOfLineItems } from '@/modules/cart/selectors/selectTotalQuantityOfLineItems';
import { FCC } from '@/types/common';

export const LineItemsCountBadge: FCC = ({ children }) => {
  const cartResult = useCurrentCart();

  const totalLineItemsCount = getSafeTotalLineItemsCount(
    selectTotalQuantityOfLineItems(cartResult.data?.currentCart?.lineItems)
  );

  return (
    <div className="relative">
      {totalLineItemsCount > 0 && (
        <div
          className={cn(
            'absolute',
            'top-0',
            'z-10',
            'pointer-events-none',
            // The button wrapped with badge may have different width depending
            // on locale for instance. It's important to position the badge
            // from center and NOT from the left or right side. Otherwise, the
            // badge may go to far to the left or to the right.
            'left-1/2',
            '-translate-x-1/2',
            'translate-y-[-3px]',
            'ml-3'
          )}
        >
          <div
            className={cn(
              'w-5',
              'h-5',
              'rounded-full',
              'border',
              'border-solid',
              'border-white',
              'flex',
              'items-center',
              'justify-center',
              'bg-apple-red'
            )}
          >
            <span className={cn('text-xs', 'text-white')}>
              {totalLineItemsCount}
            </span>
          </div>
        </div>
      )}
      {children}
    </div>
  );
};

const maxLineItemsCount = 99;
const maxTotalLineItemsCount = 100;
const getSafeTotalLineItemsCount = (counter: number) => {
  if (counter >= maxTotalLineItemsCount) {
    logger.error('Line item badge cannot handle more than 99 items');
    // eslint-disable-next-line @typescript-eslint/no-magic-numbers
    return maxLineItemsCount;
  }

  return counter;
};
