import { CaretLeft } from '@phosphor-icons/react';
import { useTranslation } from 'next-i18next';
import { useContextSelector } from 'use-context-selector';

import { DEFAULT_ICON_SIZES } from '@/core/constants/iconSizes';
import { cn } from '@/core/ui/utils';
import { ProductSearchContext } from '@/modules/product/context/ProductSearchContext';

export const ProductSearchBackButton = () => {
  const { t } = useTranslation('product');
  const combobox = useContextSelector(
    ProductSearchContext,
    (context) => context.combobox
  );

  if (!combobox.isOpen) return null;

  return (
    <button
      onClick={combobox.closeMenu}
      type="button"
      className={cn('w-12', 'h-12', 'flex', 'items-center', 'justify-center')}
      data-testid="search-back-button"
    >
      <CaretLeft
        size={DEFAULT_ICON_SIZES.md}
        alt={t('Close', {
          ns: 'common',
        })}
      />
    </button>
  );
};
