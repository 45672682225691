import { Trans } from 'next-i18next';

import { cn } from '@/core/ui/utils';

type ProductSearchMenuHeaderProps = {
  productsTitle: string;
  total?: number;
};

export const ProductSearchMenuHeader = ({
  productsTitle,
  total,
}: ProductSearchMenuHeaderProps) => (
  <div className={cn('text-body-lg', 'font-semibold', 'text-text-default')}>
    {total ? (
      <Trans
        ns="product"
        i18nKey="Found products <Gray>({{total}})</Gray>"
        components={{
          Gray: <span className={cn('font-medium', 'text-text-subtle')} />,
        }}
        values={{ total }}
      />
    ) : (
      productsTitle
    )}
  </div>
);
