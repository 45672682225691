import { useContextSelector } from 'use-context-selector';

import { useUserAddressCoordinates } from '@/core/hooks/useUserAddressCoordinates';
import { ProductSearchContext } from '@/modules/product/context/ProductSearchContext';
import { useSearchDefaultsQuery } from '@/modules/product/queries/SearchDefaults.delio.generated';

const MAX_SUGGESTED_PRODUCTS = 4;

export const useProductSearchMenu = () => {
  const {
    inputProps,
    isOpen,
    isLoading,
    isInputValueLongEnough,
    comboboxItems,
    isEmpty,
    total,
    isErrored,
    pushToSearchResults,
    closeMenu,
  } = useContextSelector(
    ProductSearchContext,
    ({
      combobox: { isOpen, inputProps, closeMenu },
      isLoading,
      isInputValueLongEnough,
      comboboxItems,
      isEmpty,
      total,
      isErrored,
      pushToSearchResults,
    }) => ({
      isOpen,
      inputProps,
      isLoading,
      isInputValueLongEnough,
      comboboxItems,
      isEmpty,
      total,
      isErrored,
      pushToSearchResults,
      closeMenu,
    })
  );
  const { coordinates, loading } = useUserAddressCoordinates();
  const query = inputProps?.value;
  const { data } = useSearchDefaultsQuery({
    variables: { coordinates },
    skip: loading,
  });
  const suggestedProducts = comboboxItems.slice(0, MAX_SUGGESTED_PRODUCTS);
  const hasMoreSuggestedProducts = Number(total) > MAX_SUGGESTED_PRODUCTS;
  const shouldShowSkeleton = isLoading && isInputValueLongEnough;

  return {
    isEmpty,
    isOpen,
    searchDefaults: data?.searchDefaults,
    suggestedProducts,
    hasMoreSuggestedProducts,
    shouldShowSkeleton,
    query,
    total,
    isErrored,
    pushToSearchResults,
    closeSearch: closeMenu,
  };
};
