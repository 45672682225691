import { CoordinatesInput } from '@/types/gqlCommon.delio.generated';

import { CategoriesQueryVariables } from '../queries/Categories.delio.generated';

export const createCategoriesQueryVariables = ({
  coordinates,
}: {
  coordinates?: CoordinatesInput;
}): CategoriesQueryVariables => ({
  offset: 0,
  limit: 100,
  where: 'parent is not defined',
  coordinates,
});
