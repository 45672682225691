import { useTranslation } from 'next-i18next';
import { MouseEvent } from 'react';

import { cn } from '@/core/ui/utils';
import { useProductLineItem } from '@/modules/cart/hooks/useProductLineItem';

import { ProductCounterButton } from './components/ProductCounterButton/ProductCounterButton';
import { useProductCounter } from './hooks/useProductCounter';

type ProductCounterProps = {
  sku: string;
  availableQuantity: number;
  isPublished: boolean;
  attributionToken?: string;
};

export const ProductCounter = ({
  availableQuantity,
  sku,
  attributionToken,
  isPublished,
}: ProductCounterProps) => {
  const { t } = useTranslation('product');
  const {
    isFolded,
    handleKeyDown,
    handleMouseLeave,
    handleMouseOver,
    unFoldCounter,
    handleDecreaseProductAmount,
    handleIncreaseProductAmount,
    isDecreaseLoading,
    isIncreaseLoading,
  } = useProductCounter({ sku, attributionToken });

  const productLineItem = useProductLineItem(sku);
  const quantityInCurrentCart = productLineItem?.quantity ?? 0;
  const isProductAvailable =
    availableQuantity > quantityInCurrentCart && isPublished;

  const increaseBtnTitle = isProductAvailable
    ? t('Add product to cart')
    : t('Out of stock');

  const increaseBtnTestId = isProductAvailable
    ? 'add-to-cart-button'
    : 'out-of-stock-button';

  const handleClick = (
    e: MouseEvent<HTMLButtonElement> | MouseEvent<HTMLDivElement>
  ) => {
    e.stopPropagation();
    unFoldCounter();
  };

  if (quantityInCurrentCart === 0) {
    return (
      <ProductCounterButton
        onClick={handleIncreaseProductAmount}
        variant="increase"
        isLoading={isIncreaseLoading}
        disabled={!isProductAvailable}
        title={increaseBtnTitle}
        loadingIndicatorTitle={t('Adding product to cart...')}
        testId={increaseBtnTestId}
        className="shadow-sm"
      />
    );
  }

  if (quantityInCurrentCart > 0 && isFolded) {
    return (
      <button
        type="button"
        onClick={handleClick}
        data-testid="folded-counter"
        className={cn(
          'w-9',
          'h-9',
          'rounded-full',
          'bg-action-primary-default',
          'flex',
          'items-center',
          'justify-center',
          'text-body-lg',
          'font-semibold',
          'text-white'
        )}
      >
        <span className="text-lg" data-testid="folded-counter-quantity">
          {quantityInCurrentCart}
        </span>
      </button>
    );
  }

  return (
    <div className={cn('relative', 'w-9', 'h-9')}>
      <div
        onClick={handleClick}
        className={cn(
          'absolute',
          'flex',
          'items-center',
          'justify-between',
          'w-[110px]',
          'right-0',
          'bg-white',
          'shadow-sm',
          'rounded-full'
        )}
        onMouseOver={handleMouseOver}
        onMouseLeave={handleMouseLeave}
        role="button"
        tabIndex={0}
        onKeyDown={handleKeyDown}
        onFocus={handleMouseOver}
        data-testid="counter"
      >
        <ProductCounterButton
          variant="decrease"
          title={t('Remove product from cart')}
          loadingIndicatorTitle={t('Removing product from cart...')}
          isLoading={isDecreaseLoading}
          onClick={handleDecreaseProductAmount}
        />
        <span
          className={cn('text-body-lg', 'font-semibold', 'text-text-default')}
          data-testid="counter-quantity"
        >
          {quantityInCurrentCart}
        </span>
        <ProductCounterButton
          onClick={handleIncreaseProductAmount}
          variant="increase"
          isLoading={isIncreaseLoading}
          disabled={!isProductAvailable}
          title={increaseBtnTitle}
          loadingIndicatorTitle={t('Adding product to cart...')}
          testId={increaseBtnTestId}
        />
      </div>
    </div>
  );
};
