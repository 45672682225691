import { APP_ROUTES } from '@/config/routing';

export const createCategoryHref = (param: CreateCategoryHrefParam) => {
  const categoriesSlug = APP_ROUTES.categories;
  if (!('l0CategoryKey' in param)) return categoriesSlug;
  const { l0CategoryKey, l1CategoryKey, l2CategoryKey } = param;

  if (l2CategoryKey && l1CategoryKey) {
    return `${categoriesSlug}/${l0CategoryKey}/${l1CategoryKey}/${l2CategoryKey}`;
  }

  if (l1CategoryKey) {
    return `${categoriesSlug}/${l0CategoryKey}/${l1CategoryKey}`;
  }

  return `${categoriesSlug}/${l0CategoryKey}`;
};

export type CreateCategoryHrefParam = {
  l0CategoryKey: string;
  l1CategoryKey?: string;
  l2CategoryKey?: string;
};
