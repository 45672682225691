export const transformSanityImageUrlToImagePlaceholder = ({
  imageHeight,
  imageWidth,
  url,
}: {
  imageWidth: number;
  imageHeight: number;
  url: string;
}) => {
  const placeholderHeight = Math.round(imageHeight * IMAGE_SCALE_FACTOR);
  const placeholderWidth = Math.round(imageWidth * IMAGE_SCALE_FACTOR);

  return `${url}?h=${placeholderHeight}&w=${placeholderWidth}&blur=2000`;
};

const IMAGE_SCALE_FACTOR = 0.1;
