import { openModal } from '@/store/slices/modalsSlice';
import { useAppDispatch } from '@/store/storeHooks';
import HamburgerMenuIcon from '@lib/theme/svg/hamburger-menu-icon.svg';

import { IconButton } from '../../../IconButton/IconButton';

export const MobileCategoriesMenu = () => {
  const dispatch = useAppDispatch();

  const onClick = () => dispatch(openModal('categories'));

  return (
    <IconButton onClick={onClick} className="h-11 w-12">
      <HamburgerMenuIcon className="size-6" />
    </IconButton>
  );
};
