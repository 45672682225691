import {
  Bag,
  BellRinging,
  MapPinLine,
  Question,
  SignOut,
  Ticket,
  User,
} from '@phosphor-icons/react';
import { useTranslation } from 'next-i18next';

import { DEFAULT_ICON_SIZES } from '@/core/constants/iconSizes';
import { useSignOut } from '@/core/hooks/useSignOut';
import DelioIcon from '@/core/images/delio-icon.svg';
import { cn } from '@/core/ui/utils';

import { AccountMenuAppBanner } from './components/AccountMenuAppBanner/AccountMenuAppBanner';
import { AccountMenuItem } from './components/AccountMenuItem/AccountMenuItem';

export const AccountMenuDesktopAuthenticatedContent = () => {
  const { t } = useTranslation('layout');
  const { signOut, isLoading } = useSignOut();

  return (
    <div>
      <AccountMenuItem
        href="/profile/your-delio"
        title={t('Your delio')}
        icon={<DelioIcon className="size-5" />}
      />
      <Divider />
      <AccountMenuItem
        href="/profile/order-history"
        title={t('Order history')}
        icon={<Bag size={DEFAULT_ICON_SIZES.md} />}
      />
      <AccountMenuItem
        href="/profile/account"
        title={t('Account')}
        icon={<User size={DEFAULT_ICON_SIZES.md} />}
      />
      <AccountMenuItem
        href="/profile/coupons"
        title={t('Coupons')}
        icon={<Ticket size={DEFAULT_ICON_SIZES.md} />}
      />
      <AccountMenuItem
        href="/profile/addresses"
        title={t('Addresses')}
        icon={<MapPinLine size={DEFAULT_ICON_SIZES.md} />}
      />
      <AccountMenuItem
        href="/profile/notifications"
        title={t('Notifications')}
        icon={<BellRinging size={DEFAULT_ICON_SIZES.md} />}
      />
      <Divider />
      <AccountMenuItem
        href="/faq"
        title={t('Help')}
        icon={<Question size={DEFAULT_ICON_SIZES.md} />}
      />
      <AccountMenuItem
        disabled={isLoading}
        onClick={signOut}
        title={t('Sign Out')}
        icon={<SignOut size={DEFAULT_ICON_SIZES.md} />}
      />

      <AccountMenuAppBanner />
    </div>
  );
};

const Divider = () => (
  <div
    className={cn(
      'h-px',
      'border-t border-border-subtle',
      'my-2',
      'rounded-full',
      'w-[calc(100%+20px)]',
      '-ml-2.5'
    )}
  />
);
