import { useContextSelector } from 'use-context-selector';

import { useHideChat } from '@/core/hooks/useHideChat';
import { cn } from '@/core/ui/utils';
import { NavBarBoxContext } from '@/modules/layout/contexts/NavBarBoxContext';
import { ProductSearchContext } from '@/modules/product/context/ProductSearchContext';
import { FCC } from '@/types/common';

export const ProductSearchDesktopOverlay: FCC = ({ children }) => {
  const { isOpen, menuProps } = useContextSelector(
    ProductSearchContext,
    ({ combobox }) => combobox
  );
  const { navBarBottomPosition } = useContextSelector(
    NavBarBoxContext,
    ({ navBarBottomPosition }) => ({
      navBarBottomPosition,
    })
  );

  useHideChat(isOpen);

  return (
    <div className={cn('flex', 'flex-col', 'grow', 'h-full', 'bg-white')}>
      {isOpen && (
        <div
          style={{ top: navBarBottomPosition }}
          className={cn(
            'fixed',
            'bottom-0',
            'inset-x-0',
            'bg-gray-800',
            'bg-opacity-90'
          )}
        />
      )}
      <div
        className={cn(
          isOpen
            ? [
                'mx-auto',
                'mb-auto',
                'w-full',
                'flex',
                'max-h-full',
                'pb-8',
                'lg:max-h-[calc(100vh-52px)]',
                'xl:max-h-[calc(100vh-60px)]',
              ]
            : ['flex', 'grow']
        )}
      >
        <ul
          className={cn(
            'bg-white',
            'flex',
            'flex-col',
            'grow',
            'rounded-xl',
            'z-10',
            // Bottom padding is necessary to render rounded corners correctly.
            // Otherwise, the content may overflow and the corners might be cut
            // in angular shape.
            isOpen && cn('pb-6')
          )}
          {...menuProps}
        >
          {children}
        </ul>
      </div>
    </div>
  );
};
