import { useCallback, useEffect, useState } from 'react';
import { useContextSelector } from 'use-context-selector';

import { useUserAddressCoordinates } from '@/core/hooks/useUserAddressCoordinates';
import { cn } from '@/core/ui/utils';
import { createCategoriesQueryVariables } from '@/modules/categories/factories/createCategoriesQueryVariables';
import { useCategoriesQuery } from '@/modules/categories/queries/Categories.delio.generated';
import { selectedL0CategorySelector } from '@/store/selectors/categoriesSelectors';
import { selectL0CategoryKey } from '@/store/slices/categoriesSlice';
import { useAppDispatch, useAppSelector } from '@/store/storeHooks';

import { NavBarBoxContext } from '../../contexts/NavBarBoxContext';

import { CategoriesNavContentHeader } from './components/CategoriesNavContentHeader/CategoriesNavContentHeader';
import { NavL1Categories } from './components/NavL1Categories/NavL1Categories';
import { useOnScrollOutside } from './hooks/useOnScrollOutside';

export const NavL1CategoriesList = () => {
  const { coordinates, loading } = useUserAddressCoordinates();

  const selectedL0CategoryKey = useAppSelector(selectedL0CategorySelector);
  const dispatch = useAppDispatch();
  const [appliedL0CategoryKey, setAppliedL0CategoryKey] = useState(
    selectedL0CategoryKey
  );
  const isDropdownOpen = !!selectedL0CategoryKey;

  useEffect(() => {
    if (!selectedL0CategoryKey) return;

    // `appliedL0CategoryKey` prevents from the blink of empty content, before
    // we close the dropdown
    setAppliedL0CategoryKey(selectedL0CategoryKey);
  }, [selectedL0CategoryKey]);

  const categoriesResult = useCategoriesQuery({
    variables: createCategoriesQueryVariables({ coordinates }),
    skip: loading,
  });

  const { results: l0Categories } = categoriesResult.data?.categories ?? {};

  const selectedL0Category = l0Categories?.find(
    ({ key }) => appliedL0CategoryKey === key
  );

  const secondaryNavBarBottomPosition = useContextSelector(
    NavBarBoxContext,
    (context) => context.secondaryNavBarBottomPosition
  );

  const [dropdownHeight, setDropdownHeight] = useState(0);

  const calculateDropdownHeight = () => {
    if (!selectedL0CategoryKey) return 0;
    if (dropdownHeight < dropdownHeightFromDesign) {
      return dropdownHeight - minSpaceFromBottom;
    }
    return dropdownHeightFromDesign;
  };

  useEffect(() => {
    if (selectedL0Category) {
      setDropdownHeight(
        document.documentElement.clientHeight -
          (secondaryNavBarBottomPosition || 0)
      );
    }
  }, [selectedL0Category, secondaryNavBarBottomPosition]);

  const { elementRef } = useOnScrollOutside(
    useCallback(() => {
      dispatch(selectL0CategoryKey(null));
      setAppliedL0CategoryKey('');
    }, [dispatch])
  );

  return (
    <div
      ref={elementRef}
      className={cn(
        'absolute',
        'w-full',
        'bg-white',
        'top-14',
        'left-0',
        'shadow-[0_12px_40px_12px_rgba(0,7,26,0.05)]',
        isDropdownOpen
          ? cn('rounded-b-2xl', 'opacity-100')
          : cn('opacity-0', 'pointer-events-none'),
        'delay-100',
        'transition-all',
        'duration-300'
      )}
      style={{
        height: calculateDropdownHeight(),
        overflow: dropdownHeight < dropdownHeightFromDesign ? 'auto' : 'hidden',
      }}
    >
      <CategoriesNavContentHeader
        selectedCategory={selectedL0Category}
        // it ensures we show image for the actually selected category,
        // not the previous one
        shouldShowImage={selectedL0CategoryKey === appliedL0CategoryKey}
      />
      <NavL1Categories cachedCategory={selectedL0Category} />
    </div>
  );
};

const dropdownHeightFromDesign = 536;
const minSpaceFromBottom = 20;
