import { useTranslation } from 'next-i18next';
import { ReactNode } from 'react';

import { cn } from '@/core/ui/utils';

type ProductEmptyStateListProps = {
  className?: string;
};

export const ProductEmptyStateList = ({
  className,
}: ProductEmptyStateListProps) => {
  const { t } = useTranslation('product');

  return (
    <ul
      className={cn(
        'flex',
        'flex-col',
        'gap-2',
        'text-body-md',
        'text-text-default',
        'list-none',
        className
      )}
    >
      <Li>{t('Check if all worlds are correct')}</Li>
      <Li>{t('Try to use different or more general words')}</Li>
      <Li>{t('Try to use fewer words')}</Li>
    </ul>
  );
};

const Li = ({ children }: { children: ReactNode }) => (
  <li className={cn('flex', 'items-center', 'gap-2')}>
    <Dot />
    {children}
  </li>
);

const Dot = () => (
  <div className={cn('w-1', 'h-1', 'bg-current', 'rounded-sm')} />
);
