import { Media } from '@lib/theme/components/Media';

import { AccountMenuDesktop } from './components/AccountMenuDesktop/AccountMenuDesktop';
import { AccountMenuMobile } from './components/AccountMenuMobile/AccountMenuMobile';

export const AccountMenu = () => (
  <>
    <Media lessThan="xl">
      <AccountMenuMobile />
    </Media>
    <Media greaterThanOrEqual="xl">
      <AccountMenuDesktop />
    </Media>
  </>
);
