import { captureException } from '@sentry/nextjs';
import { useRouter } from 'next/router';
import { useContext, useState } from 'react';

import { clearChatUser } from '@/core/ui/utils';
import { UtmContext } from '@/modules/analytics/contexts/UtmContext';
import { useSession } from '@/modules/auth/hooks/useSession';

export const useSignOut = () => {
  const destroySession = useSession('destroySession');
  const { clearUtm } = useContext(UtmContext);
  const router = useRouter();
  const [isLoading, setIsLoading] = useState(false);

  const signOut = async () => {
    setIsLoading(true);
    try {
      await router.push('/');
      await destroySession();
      clearUtm();
      clearChatUser();
    } catch (err) {
      captureException(err);
    } finally {
      setIsLoading(false);
    }
  };

  return { signOut, isLoading };
};
