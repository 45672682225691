import { useEffect, useState } from 'react';

export const useIsIos = () => {
  const [isIos, setIsIos] = useState(false);

  useEffect(() => {
    setIsIos(detectIos(window.navigator.userAgent));
  }, []);

  return isIos;
};

const detectIos = (userAgent: string) => /iPhone|iPod|iPad/i.test(userAgent);
