import dynamic from 'next/dynamic';
import { useTranslation } from 'next-i18next';

import { cn } from '@/core/ui/utils';

import { ProductEmptyStateContact } from './components/ProductEmptyStateContact/ProductEmptyStateContact';
import { ProductEmptyStateHeader } from './components/ProductEmptyStateHeader/ProductEmptyStateHeader';
import { ProductEmptyStateList } from './components/ProductEmptyStateList/ProductEmptyStateList';

const SearchDropdownEmptyStateImage = dynamic(
  async () => import('./images/searchDropdownEmptyStateImage.svg'),
  { ssr: false }
);

const SearchEmptyStateImage = dynamic(
  async () => import('./images/searchEmptyStateImage.svg'),
  { ssr: false }
);

type Props = {
  query?: string;
  isSearch?: boolean;
  className?: string;
};

export const ProductEmptyState = ({
  query = '',
  isSearch = false,
  className,
}: Props) => {
  const { t } = useTranslation('product');

  return (
    <section
      className={cn(
        'lg:flex',
        isSearch ? 'lg:gap-4' : 'lg:gap-10',
        !isSearch && 'mb-4',
        className
      )}
    >
      <div className={cn(isSearch ? 'lg:max-w-[511px]' : 'lg:max-w-[751px]')}>
        <ProductEmptyStateHeader query={`"${query}"`} isSearch={isSearch} />
        <ProductEmptyStateList
          className={cn(isSearch ? 'mt-4' : 'mt-5', 'mb-8')}
        />
        <h3
          className={cn(
            'text-title-sm',
            'text-text-default',
            'font-medium',
            'mb-5',
            'lg:hidden'
          )}
        >
          {t('Suggest a product')}
        </h3>
        <section
          className={cn(
            'flex',
            'flex-col',
            'gap-4',
            'lg:flex-row',
            'lg:max-w-[511px]',
            'w-full',
            'border-t',
            'border-border-subtle',
            'pt-6',
            'lg:pt-8'
          )}
        >
          <ProductEmptyStateContact />
        </section>
      </div>
      <div
        className={cn(
          isSearch
            ? cn('justify-center', 'hidden', 'lg:flex', 'mt-2', 'pb-4')
            : 'flex',
          'items-center',
          'flex-1'
        )}
      >
        {isSearch ? (
          <SearchDropdownEmptyStateImage className={cn('w-[164px]')} />
        ) : (
          <SearchEmptyStateImage className={cn('w-[276px]')} />
        )}
      </div>
    </section>
  );
};
