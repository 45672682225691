import { transformSanityImageUrlToImagePlaceholder } from './transform-sanity-image-url-to-image-placeholder';
import { transformSanityImageUrlToImageUrl } from './transform-sanity-image-url-to-image-url';

export const createImage = ({
  imageUrl,
  alt,
  imageWidth,
  imageHeight,
}: {
  imageUrl: string | null | undefined;
  alt: string | undefined;
  imageWidth: number;
  imageHeight: number;
}) => {
  if (!imageUrl) return undefined;

  return {
    width: imageWidth,
    height: imageHeight,
    alt: alt ?? '',
    src: transformSanityImageUrlToImageUrl({
      imageWidth,
      imageHeight,
      dpr: 1,
      url: imageUrl,
    }),
    placeholder: transformSanityImageUrlToImagePlaceholder({
      imageWidth,
      imageHeight,
      url: imageUrl,
    }),
    srcSetObj: {
      '2x': transformSanityImageUrlToImageUrl({
        imageWidth,
        imageHeight,
        dpr: 2,
        url: imageUrl,
      }),
      '3x': transformSanityImageUrlToImageUrl({
        imageWidth,
        imageHeight,
        dpr: 3,
        url: imageUrl,
      }),
    },
  };
};
