import { CheckCircle } from '@phosphor-icons/react';
import { useTranslation } from 'next-i18next';

import { DEFAULT_ICON_SIZES } from '@/core/constants/iconSizes';

import { AccountMenuAuthButtons } from '../../../AccountMenuAuthButtons/AccountMenuAuthButtons';

export const AccountMenuDesktopUnauthenticatedContent = () => {
  const { t } = useTranslation('layout');

  const bullets = [
    { id: 2, title: t('Access to discount coupons') },
    { id: 1, title: t('Same-day delivery shopping') },
    { id: 0, title: t('The ability to order on the Żabka Jush app!') },
  ];

  return (
    <div className="p-1.5">
      <AccountMenuAuthButtons />
      <div className="mb-3 mt-6">
        <p className="text-body-sm font-semibold">
          {t('The delio account is')}
        </p>
        <div className="mt-3 flex flex-col gap-y-2">
          {bullets.map(({ title, id }) => (
            <div key={id} className="flex items-center gap-x-1.5">
              <CheckCircle
                weight="fill"
                size={DEFAULT_ICON_SIZES.sm}
                className="text-icon-success"
              />
              <p className="pr-2 text-body-sm font-medium text-text-subtle">
                {title}
              </p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
