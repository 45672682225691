import { PopoverButtonProps } from '@headlessui/react';
import { forwardRef, MouseEvent, ReactNode } from 'react';

import { cn } from '@/core/ui/utils';

export const IconButton = forwardRef<
  HTMLButtonElement,
  {
    children: ReactNode;
    onClick?: (e: MouseEvent<HTMLButtonElement>) => void;
    className?: string;
  } & PopoverButtonProps
>(
  (
    {
      children,
      onClick,
      className,
      id,
      onBlur,
      onFocus,
      onKeyDown,
      onKeyUp,
      onMouseDown,
      onPointerDown,
      onPointerEnter,
    },
    ref
  ) => (
    <button
      ref={ref}
      type="button"
      className={cn(
        'flex h-14 w-14 items-center justify-center rounded-lg focus-visible:outline-none focus-visible:ring-0',
        className
      )}
      id={id}
      onClick={onClick}
      onBlur={onBlur}
      onFocus={onFocus}
      onKeyDown={onKeyDown}
      onKeyUp={onKeyUp}
      onMouseDown={onMouseDown}
      onPointerDown={onPointerDown}
      onPointerEnter={onPointerEnter}
    >
      <div className="flex flex-col items-center justify-center">
        {children}
      </div>
    </button>
  )
);
