/**
 * A hook that helps to hide the categories dropdown when the user scrolls
 * the page.
 *
 * We hide the dropdown when user scrolls for better UX on devices with smaller
 * screens e.g. 13'. In such case the dropdown covers huge amount of space
 * of the screen and it's difficult to hide it. So we add additional option to
 * hide the dropdown when the user starts scrolling.
 *
 * The gotcha here is that the user may also scroll inside the dropdown in some
 * cases. In such case, we should not hide the dropdown since the user may
 * want to see the content below the fold.
 */
import { useCallback, useEffect, useRef } from 'react';
import { useThrottledCallback } from 'use-debounce';

const THROTTLED_WAIT_MS = 300;

export const useOnScrollOutside = (callback: () => void) => {
  const elementRef = useRef<HTMLDivElement>(null);

  const handleScroll = useThrottledCallback(
    useCallback(() => {
      const element = elementRef.current;

      const hasScrollBar =
        element && element.offsetHeight - element.scrollHeight > 0;

      if (!hasScrollBar) return;

      callback();
    }, [callback]),
    THROTTLED_WAIT_MS,
    {
      leading: false,
    }
  );

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [handleScroll]);

  return {
    elementRef,
  };
};
