import { pushGtmEvent } from '@/core/services/pushGtmEvent';
import { ProductSearchQuery } from '@/modules/product/queries/ProductSearch.delio.generated';

type SearchResultsEventArgs = {
  searchTerm: string;
  items: ProductSearchQuery['productSearch']['results'];
};
export const sendSearchResultsEvent = ({
  searchTerm,
  items,
}: SearchResultsEventArgs) =>
  pushGtmEvent({
    event: 'view_search_results',
    search_term: searchTerm,
    items,
  });
