import { useContextSelector } from 'use-context-selector';

import { cn } from '@/core/ui/utils';
import { ProductSearchContext } from '@/modules/product/context/ProductSearchContext';

type ProductSearchBrandsProps = {
  readonly brands: ReadonlyArray<string>;
  headerTitle: string;
  className?: string;
};

export const ProductSearchBrands = ({
  brands,
  headerTitle,
  className,
}: ProductSearchBrandsProps) => {
  const { combobox } = useContextSelector(
    ProductSearchContext,
    ({ combobox }) => ({
      combobox,
    })
  );

  const handleClick = (query: string) => {
    combobox.setInputValue(query);
  };

  return (
    <div className={cn('pl-4', className)}>
      <h2
        className={cn(
          'text-body-md',
          'font-semibold',
          'text-text-default',
          'mb-4'
        )}
      >
        {headerTitle}
      </h2>
      <div className={cn('flex', 'flex-wrap', 'gap-x-1', 'gap-y-2')}>
        {brands.map((brand) => (
          <button
            key={brand}
            type="button"
            className={cn(
              'text-body-sm',
              'font-medium',
              'py-1.5',
              'px-4',
              'rounded-full',
              'whitespace-nowrap',
              'transition-colors',
              'text-text-default',
              'bg-surface-neutral-default',
              'hover:bg-surface-neutral-hovered'
            )}
            onClick={() => handleClick(brand)}
          >
            {brand}
          </button>
        ))}
      </div>
    </div>
  );
};
