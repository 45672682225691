import { TrendUp } from '@phosphor-icons/react';
import { useContextSelector } from 'use-context-selector';

import { DEFAULT_ICON_SIZES } from '@/core/constants/iconSizes';
import { cn } from '@/core/ui/utils';
import { ProductSearchContext } from '@/modules/product/context/ProductSearchContext';

type ProductSearchMobileMenuPillsProps = {
  pills: string[];
  title: string;
};

export const ProductSearchMobileMenuPills = ({
  pills,
  title,
}: ProductSearchMobileMenuPillsProps) => {
  const { setInputValue } = useContextSelector(
    ProductSearchContext,
    ({ combobox: { setInputValue } }) => ({
      setInputValue,
    })
  );

  const handleClick = (pill: string) => {
    setInputValue(pill);
  };

  return (
    <>
      <div
        className={cn(
          'pt-5',
          'pb-4',
          'pl-4',
          'text-body-sm',
          'text-text-subtle'
        )}
      >
        {title}
      </div>
      <div
        className={cn(
          'flex',
          'gap-2',
          'items-center',
          'px-4',
          'pb-5',
          'overflow-x-auto',
          'no-scrollbar'
        )}
      >
        {pills.map((pill) => (
          <button
            key={pill}
            className={cn(
              'py-3',
              'px-4',
              'flex',
              'items-center',
              'gap-2.5',
              'text-body-sm',
              'font-medium',
              'bg-action-secondary-default',
              'rounded-[32px]',
              'whitespace-nowrap'
            )}
            onClick={() => handleClick(pill)}
            type="button"
          >
            <div>
              <TrendUp size={DEFAULT_ICON_SIZES.sm} />
            </div>
            {pill}
          </button>
        ))}
      </div>
      <div className={cn('w-full', 'border-t', 'border-border-subtle')} />
    </>
  );
};
