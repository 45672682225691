import { Trans } from 'next-i18next';

import { Badge } from '@/core/ui/components/Badge/Badge';
import { FormattedPrice } from '@/core/ui/components/FormattedPrice/FormattedPrice';
import { ProductBadgesList } from '@/core/ui/components/ProductBadgesList/ProductBadgesList';
import { Product } from '@/modules/product/queries/types';
import { PriceDifference } from '@/modules/product/services/checkPriceDifference';

type ProductSearchSuggestedProductBadgesProps = {
  priceDifference: PriceDifference;
  tags?: Product['attributes']['tags'];
  disabled: boolean;
};

export const ProductSearchSuggestedProductBadges = ({
  priceDifference,
  tags,
  disabled,
}: ProductSearchSuggestedProductBadgesProps) => (
  <>
    {priceDifference.discountType ? (
      <Badge variant={disabled ? 'disabled' : 'default'}>
        {priceDifference.discountType === 'PERCENTAGE' ? (
          <Trans
            ns="product"
            i18nKey="-{{value}}%"
            values={{
              value: Math.ceil(priceDifference.difference),
            }}
          />
        ) : (
          <FormattedPrice
            hideDecimals
            centAmount={priceDifference.difference}
            currencyCode={priceDifference.currencyCode}
          />
        )}
      </Badge>
    ) : null}
    <ProductBadgesList
      tags={tags}
      direction="row"
      maxNumberOfBadgesOnDesktop={3}
      maxNumberOfBadgesOnMobile={2}
      showOnlyIcon
      slim
    />
  </>
);
