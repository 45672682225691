import { cn } from '@/core/ui/utils';
import { ProductEmptyState } from '@/modules/product/components/ProductEmptyState/ProductEmptyState';

type ProductSearchEmptyStateProps = {
  query?: string;
};

export const ProductSearchEmptyState = ({
  query,
}: ProductSearchEmptyStateProps) => (
  <div className={cn('pt-6', 'px-4', 'md:px-8', 'lg:pt-8', 'lg:px-6')}>
    <ProductEmptyState isSearch query={query} />
  </div>
);
