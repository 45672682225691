import { useContextSelector } from 'use-context-selector';

import { useUserAddressCoordinates } from '@/core/hooks/useUserAddressCoordinates';
import { ProductSearchContext } from '@/modules/product/context/ProductSearchContext';
import { useSearchDefaultsQuery } from '@/modules/product/queries/SearchDefaults.delio.generated';

const MAX_PRODUCTS = 15;

export const useProductSearchMobileMenu = () => {
  const {
    isOpen,
    isLoading,
    isInputValueLongEnough,
    comboboxItems,
    isEmpty,
    total,
    isErrored,
    pushToSearchResults,
    closeMenu,
  } = useContextSelector(
    ProductSearchContext,
    ({
      combobox: { isOpen, closeMenu },
      isLoading,
      isInputValueLongEnough,
      comboboxItems,
      isEmpty,
      total,
      isErrored,
      pushToSearchResults,
    }) => ({
      isOpen,
      isLoading,
      isInputValueLongEnough,
      comboboxItems,
      isEmpty,
      total,
      isErrored,
      pushToSearchResults,
      closeMenu,
    })
  );
  const { coordinates, loading } = useUserAddressCoordinates();
  const { data } = useSearchDefaultsQuery({
    variables: { coordinates },
    skip: loading,
  });

  const pills =
    data?.searchDefaults?.queries && data?.searchDefaults?.brands
      ? [...data.searchDefaults.queries, ...data.searchDefaults.brands]
      : [];

  const hasMoreSuggestedProducts = Number(total) > MAX_PRODUCTS;

  return {
    isEmpty,
    isOpen,
    pills,
    isLoading: isLoading && isInputValueLongEnough,
    searchDefaults: data?.searchDefaults,
    comboboxItems,
    total,
    hasMoreSuggestedProducts,
    isErrored,
    pushToSearchResults,
    closeSearch: closeMenu,
  };
};
