import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';

import { Button } from '@/core/ui/components/Button/Button';

export const AccountMenuAuthButtons = () => {
  const { t } = useTranslation('layout');
  const { push, asPath } = useRouter();

  const redirectToSighIn = async () => {
    await push({
      pathname: '/sign-in',
      query: { afterAuthorizationDestination: asPath },
    });
  };

  const redirectToSignUp = async () => {
    await push({
      pathname: '/sign-up',
      query: { afterAuthorizationDestination: asPath },
    });
  };

  return (
    <>
      <Button className="w-full" onClick={redirectToSighIn}>
        {t('Sign in')}
      </Button>
      <div className="my-3 flex w-full items-center justify-center">
        <div className="mr-2 w-full border-t border-border-subtle" />
        <p className="text-body-sm font-medium text-text-subtlest">{t('or')}</p>
        <div className="ml-2 w-full border-t border-border-subtle" />
      </div>
      <Button
        className="w-full"
        buttonStyle="outlined"
        onClick={redirectToSignUp}
      >
        {t('Create an account')}
      </Button>
    </>
  );
};
