import { useTranslation } from 'next-i18next';

import { cn } from '@/core/ui/utils';
import { SpinnerIcon } from '@lib/theme/components/SpinnerIcon';

export const ProductSearchFieldLoader = () => {
  const { t } = useTranslation('product');

  return (
    <SpinnerIcon
      className={cn('w-5', 'h-5')}
      title={t('Loading search results...')}
    />
  );
};
