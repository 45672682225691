import { FC } from '@/types/common';
import { AccessibleSvg } from '@lib/theme/components/AccessibleSvg';

import LogoSvg from '../images/logo.svg';

export const Logo: FC<LogoIconProps> = ({ className, title }) => (
  <AccessibleSvg className={className} SvgComponent={LogoSvg} title={title} />
);

type LogoIconProps = {
  className?: string;
  title: string;
};
