import { ChatsTeardrop, Envelope } from '@phosphor-icons/react';
import { Trans, useTranslation } from 'next-i18next';
import { MouseEvent, ReactNode } from 'react';

import { DELIO_CONTACT_MAIL } from '@/config/consts';
import { DEFAULT_ICON_SIZES } from '@/core/constants/iconSizes';
import { cn, showChat } from '@/core/ui/utils';
import { AUTH_ROLE } from '@/modules/auth/contexts/AuthContext';
import { useSession } from '@/modules/auth/hooks/useSession';
import { FCC } from '@/types/common';

export const ProductEmptyStateContact = () => {
  const { t } = useTranslation('product');
  const role = useSession('authRole');
  const isAuthenticated = role === AUTH_ROLE.AUTHENTICATED;

  const subject: string = t('Problem finding the product');
  const message: string = t(
    'Hello, I cannot find the selected product in the website:'
  );

  return (
    <>
      {isAuthenticated && (
        <ContactItem
          icon={<ChatsTeardrop size={DEFAULT_ICON_SIZES.md} />}
          title={t('Write in chat')}
        >
          <Trans
            ns="product"
            i18nKey="Need support in your search"
            components={{
              Link: (
                <ContactLink
                  onClick={(e) => {
                    e.preventDefault();
                    showChat();
                  }}
                />
              ),
            }}
          />
        </ContactItem>
      )}
      <ContactItem
        icon={<Envelope size={DEFAULT_ICON_SIZES.md} />}
        title={t('Write email')}
      >
        <Trans
          ns="product"
          i18nKey="Can't find your favorite product? Write to us"
          components={{
            Link: (
              <ContactLink
                href={`mailto:${DELIO_CONTACT_MAIL}?subject=${subject}&body=${message}`}
              />
            ),
          }}
        />
      </ContactItem>
    </>
  );
};

const ContactItem: FCC<{ icon: ReactNode; title: string }> = ({
  icon,
  title,
  children,
}) => (
  <article className={cn('flex', 'gap-3', 'text-text-default')}>
    <figure className="size-5">{icon}</figure>
    <div>
      <h3 className={cn('text-body-md', 'font-semibold')}>{title}</h3>
      <p className={cn('text-text-subtle', 'text-body-md', 'mt-2')}>
        {children}
      </p>
    </div>
  </article>
);

const ContactLink: FCC<{
  href?: string;
  onClick?: (e: MouseEvent<HTMLAnchorElement>) => void;
}> = ({ href = '#', children, onClick }) => (
  <a
    className={cn(
      'underline',
      'text-text-default',
      'font-semibold',
      'block',
      'lg:inline'
    )}
    href={href}
    onClick={onClick}
  >
    {children}
  </a>
);
