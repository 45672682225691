import { X } from '@phosphor-icons/react';

import { DEFAULT_ICON_SIZES } from '@/core/constants/iconSizes';
import { cn } from '@/core/ui/utils';

type ProductSearchFieldClearButtonProps = {
  onClick: () => void;
};

export const ProductSearchFieldClearButton = ({
  onClick,
}: ProductSearchFieldClearButtonProps) => (
  <button
    onClick={onClick}
    type="button"
    className={cn('w-10', 'h-10', 'flex', 'items-center', 'justify-center')}
    data-testid="search-clear-button"
  >
    <X size={DEFAULT_ICON_SIZES.md} alt="Close location selection" />
  </button>
);
