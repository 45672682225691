import { useContextSelector } from 'use-context-selector';

import { ProductSearchContext } from '@/modules/product/context/ProductSearchContext';
import { checkPriceDifference } from '@/modules/product/services/checkPriceDifference';

export const useProductSearchSuggestedProduct = (index: number) => {
  const { suggestedProducts, pushToProductPage, attributionToken } =
    useContextSelector(
      ProductSearchContext,
      ({ suggestedProducts, pushToProductPage, attributionToken }) => ({
        suggestedProducts,
        pushToProductPage,
        attributionToken,
      })
    );

  const product = suggestedProducts?.[index];
  const isOnStock = product?.isPublished && product?.availableQuantity > 0;
  const discount = product?.price?.discounted;
  const [imageUrl = ''] = product?.imagesUrls ?? '';
  const pricePercentageDifference = checkPriceDifference(
    discount?.discount.value
  );
  const hasBadges =
    product?.productType === 'complex-bundle' ||
    pricePercentageDifference.discountType ||
    Boolean(product?.attributes?.tags?.length);

  return {
    hasBadges,
    imageUrl,
    isOnStock,
    product,
    pushToProductPage,
    attributionToken,
    discount,
    pricePercentageDifference,
  };
};
