import { useRouter } from 'next/router';

import { publicEnv } from '@lib/env/public-env-vars';

export enum CART_SIDEBAR_STATE {
  OPEN = 'open',
  CLOSE = 'close',
}

export const useCreateCartSidebarUrl = (state: CART_SIDEBAR_STATE) => {
  const { asPath } = useRouter();
  const cartSidebarUrl = new URL(
    `${publicEnv.NEXT_PUBLIC_WEBSITE_URL}${asPath}`
  );

  if (state === CART_SIDEBAR_STATE.OPEN) {
    cartSidebarUrl.searchParams.set('cartSidebar', state);
  } else {
    cartSidebarUrl.searchParams.delete('cartSidebar');
  }

  return cartSidebarUrl;
};
