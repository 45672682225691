import { useRouter } from 'next/router';
import { useEffect, useMemo } from 'react';

import { transformQueryStringToString } from '../transformers/transformQueryStringToString';

type AppsFlyerQueryParam = 'searchPhrase' | 'address';

export const useAppsFlyer = ({
  appsFlyerQueryParam,
  enabled,
  onSuccess,
}: {
  appsFlyerQueryParam: AppsFlyerQueryParam;
  onSuccess: () => void | Promise<void>;
  enabled: boolean;
}) => {
  const { query, pathname, replace } = useRouter();

  const shouldRunEffect = useMemo(
    () => typeof query[appsFlyerQueryParam] === 'string' && enabled,
    [appsFlyerQueryParam, enabled, query]
  );

  useEffect(() => {
    if (!shouldRunEffect) return;

    onSuccess();
  }, [onSuccess, shouldRunEffect]);

  const removeAppsFlyerQueryParam = async () => {
    delete query[appsFlyerQueryParam];

    await replace({ pathname, query }, undefined, {
      shallow: true,
    });
  };

  return {
    removeAppsFlyerQueryParam,
    queryParamValue: transformQueryStringToString(query[appsFlyerQueryParam]),
  };
};
