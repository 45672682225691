import { createContext } from 'use-context-selector';

import { useProductSearch } from '../components/ProductSearch/hooks/useProductSearch';

export const ProductSearchContext = createContext<ProductSearchContextType>({
  get combobox(): never {
    throw new Error('Missing ProductSearchContext');
  },
  get comboboxItems(): never {
    throw new Error('Missing ProductSearchContext');
  },
  get suggestedProducts(): never {
    throw new Error('Missing ProductSearchContext');
  },
  get pushToSearchResults(): never {
    throw new Error('Missing ProductSearchContext');
  },
  get pushToSearchResultsStatus(): never {
    throw new Error('Missing ProductSearchContext');
  },
  get isLoading(): never {
    throw new Error('Missing ProductSearchContext');
  },
  get isErrored(): never {
    throw new Error('Missing ProductSearchContext');
  },
  get isInputValueLongEnough(): never {
    throw new Error('Missing ProductSearchContext');
  },
  get pushToProductPage(): never {
    throw new Error('Missing ProductSearchContext');
  },
  get isEmpty(): never {
    throw new Error('Missing ProductSearchContext');
  },
  get attributionToken(): never {
    throw new Error('Missing ProductSearchContext');
  },
  get total(): never {
    throw new Error('Missing ProductSearchContext');
  },
});

type ProductSearchContextType = ReturnType<typeof useProductSearch>;
